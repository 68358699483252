<div id="content">
	 <sa-widgets-grid>
		<div class="row">
			<div class="col-md-12">
				<div class="col-md-9 aerial-table" style="height:auto">
					<span class="material-icons-outlined aerial-area-icon">location_away</span> <span class="aerial-area-title">{{'aerial.menu.users' | translate}}</span>
					<div class="pull-right" *ngIf="showFoundInstallationResponse"><span class="aerial-alert-normal">{{foundInstallationResponseText}}</span></div>
					<sa-datatable [options]="options"
                		filter="false" paginationLength="true"
                		tableClass="display projects-table table table-striped table-bordered table-hover" [ngClass]="{'aerial-user-list-hidde-alerts': !displayAlerts}" id="userListDataTable" [refreshTime]="refreshTime"
                		width="100%">
                		<thead>
	                		<tr>
		                  		<th class="hasinput" style="width:20%">
		                    		<input type="text" class="form-control" placeholder="{{'aerial.user.name.placeholder' | translate}}">
		                  		</th>
		                  		<th class="hasinput" style="width:20%">
		                    		<input type="text" class="form-control" placeholder="{{'aerial.user.address.placeholder' | translate}}">
		                  		</th>
		                  		<th class="" style="width:14%">
		                    		<div style="float:left;">{{'aerial.user.filter.display.only.alerts' | translate}}</div><div style="float:left;margin-left:5px;margin-top:-2px;"><input name="activity" #activityCheckButton ng-control="activity" type="checkbox" (click)="setActivityAlertMode()"></div>
			                    	<input type="text" #activityInputText class="form-control" style="display:none;" [(ngModel)]="activityFilterAlert">
		                  		</th>
		                  		<th class="" style="width:14%">
		                    		<div style="float:left;">{{'aerial.user.filter.display.only.alerts' | translate}}</div><div style="float:left;margin-left:5px;margin-top:-2px;"><input name="wakeup" #wakeupCheckButton ng-control="wakeup" type="checkbox" (click)="setWakeUpAlertMode()"></div>
			                    	<input type="text" #wakeupInputText class="form-control" style="display:none;" [(ngModel)]="wakeupFilterAlert">
		                  		</th>
		                  		<th class="" style="width:8%">
		                    		&nbsp;
		                  		</th>
		                  		<th class="" style="width:12%">
		                    		&nbsp;
		                  		</th>
								<th class="" style="width:10%">
		                    		<div style="float:left;">{{'aerial.user.filter.display.only.alerts' | translate}}</div><div style="float:left;margin-left:5px;margin-top:-2px;"><input name="otheralert" #otherAlertCheckButton ng-control="otherAlert" type="checkbox" (click)="setOtherAlertMode()"></div>
			                    	<input type="text" #otherAlertInputText class="form-control" style="display:none;" [(ngModel)]="otherAlertFilterAlert">
		                  		</th>
		                  		<th class="" style="width:12%">
									<div style="float:left;">{{'aerial.user.filter.display.only.alerts' | translate}}</div><div style="float:left;margin-left:5px;margin-top:-2px;"><input name="deviceStatus" #deviceStatusCheckButton ng-control="deviceStatus" type="checkbox" (click)="setDeviceStatusAlertMode()"></div>					
			                    	<input type="text" #deviceStatusInputText class="form-control" style="display:none;" [(ngModel)]="deviceStatusFilterAlert">
		                  		</th>
							</tr>
							<tr>
		                  		<th data-class="expand" style="vertical-align:middle">{{'aerial.user' | translate }}</th>
		                  		<th style="vertical-align:middle">{{'aerial.address'|translate}}</th>
		                  		<th style="vertical-align:middle">{{'aerial.alert.inactivity' | translate }}</th>
		                  		<th style="vertical-align:middle">{{'aerial.alert.wakeUp' | translate }}</th>
		                  		<th style="vertical-align:middle">{{'aerial.alert.inactivityTime' | translate }}</th>
		                  		<th style="vertical-align:middle">{{'aerial.alert.sleepDisruptions' | translate }}</th>
								<th style="vertical-align:middle">{{'aerial.alert.other' | translate }}</th>
		                  		<th style="vertical-align:middle">{{'aerial.installation.connectionState' | translate }}</th>
	                		</tr>
                		</thead>
					</sa-datatable>
				</div>
				<div class="col-md-3">
					<div class="col-md-12" *ngIf="aerialAutoSearchActive" style="margin-bottom:10px;">
						<button class="aerial-action-button" style="margin-left: 35px" (click)="searchAerialInstallations()"><i class="material-icons-outlined aerial-color-grey aerial-action-button-icon">person_search</i> {{'aerial.search.installations' | translate}}</button>
					</div>
					<div class="col-md-12" style="margin-bottom:10px;" *ngFor="let alertSummary of alertSummaryList">
						<aerial-alertbudget *ngIf="alertSummary.value>0" style="cursor:pointer;" iconClass="{{alertSummary.iconClass}} {{alertSummary.value>0?'aerial-alert-icon-warning':'aerial-alert-icon-normal'}}" iconName="{{alertSummary.iconName}}" alertAmount="{{alertSummary.value}}" alertText="{{alertSummary.name | translate}}" (click)="filterOnlyAlerts(alertSummary.code)"></aerial-alertbudget>
						<aerial-alertbudget *ngIf="alertSummary.value<=0" iconClass="{{alertSummary.iconClass}} {{alertSummary.value>0?'aerial-alert-icon-warning':'aerial-alert-icon-normal'}}" iconName="{{alertSummary.iconName}}" alertAmount="{{alertSummary.value}}" alertText="{{alertSummary.name | translate}}"></aerial-alertbudget>
					</div>
				</div>
			</div>
		</div>
	</sa-widgets-grid>
</div>
