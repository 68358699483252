import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import {AerialPortalComponent} from "./portal.component";

const routes: Routes = [
	/*{
  		path: '',
  		component: AerialPortalComponent,
  		data: {pageTitle: 'Portal'}
	},*/
	{
	    path: '', redirectTo: 'users', pathMatch: 'full'
	},
	{
		path: 'dashboard',
		loadChildren:'./dashboard/dashboard.module#AerialDashboardModule',
	},
	{
		path: 'users',
		loadChildren:'./users/users.module#AerialUsersModule',
	},
	{
		path: 'administrators',
		loadChildren:'./administrators/administrators.module#AerialAdministratorsModule',
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AerialPortalRoutingModule { }

