<div class="aerial-fullscreen-div ">
	<div class="aerial-login-bg" style="flex: 1; min-height: 1700px"></div>
	<div class="aerial-login-sidebar">
		<div style="text-align:center; padding-top: 100px">
			<img src="../../assets/img/aerial/logo_aerialportalhub_340x100px.png" />
		</div>
		<div style="text-align:center; padding-top: 60px;">
			<img src="../../assets/img/aerial/marca_aerialportalhub_115x115px.png" />
		</div>
		<div class="well no-padding" style="border:none;padding-top:60px!important;background-color:white!important">
			<form class="smart-form client-form"  style="border:none; margin-right:auto; margin-left: auto; width:75%">
            <header class="aerial-login-header" *ngIf="!forgotPasswordMode">
              {{'aerial.login.initSession'|translate}}
            </header>
			<header class="aerial-login-header" *ngIf="forgotPasswordMode">
              {{'aerial.login.requestNewPasswordTitle'|translate}}
            </header>
            <fieldset  style="border:none">
              <section *ngIf="!askCode">
                <label class="label aerial-login-label">{{'aerial.login.user'|translate}}</label>
                <label class="input"> <i class="icon-append fa fa-user"></i>
                  <input type="text" name="account" data-smart-validate-input="" required data-message-required="Usuario" [(ngModel)]="loginInfo.account">
                  <b class="tooltip tooltip-top-right"><i class="fa fa-user txt-color-teal"></i> {{'aerial.login.insertUser'|translate}}</b></label>
              </section>
              <section *ngIf="!askCode && !forgotPasswordMode">
                <label class="label aerial-login-label">{{'aerial.login.password'|translate}}</label>
                <label class="input"> <i class="icon-append fa fa-lock"></i>
                  <input type="password" name="password" data-smart-validate-input="" required="" data-minlength="3" [(ngModel)]="loginInfo.password">
                  <b class="tooltip tooltip-top-right"><i class="fa fa-lock txt-color-teal"></i> {{'aerial.login.insertPassword'|translate}}</b> </label>
              </section>
			  <section *ngIf="!askCode && !forgotPasswordMode">
				<div class="note">
                  <!-- a routerLink="/auth/forgot-password" class="aerial-login-comment">{{'aerial.login.forgotPassword'|translate}}</a -->
				  <div class="aerial-login-link" (click)="changeToForgotPassword()">{{'aerial.login.forgotPassword'|translate}}</div>
                </div>
			  </section>
			  <section *ngIf="forgotPasswordMode">
				<div class="note">
				  <div class="aerial-login-comment">{{'aerial.login.requestNewPassword.instructions'|translate}}</div>
                </div>
			  </section>
			  <section *ngIf="askCode">
					<label class="label aerial-login-label">{{'aerial.login.code'|translate}}</label>
                	<label class="input"> <i class="icon-append fa fa-lock"></i>
                  	<input type="text" name="code" data-smart-validate-input="" required="" data-minlength="1" [(ngModel)]="loginInfo.code">
                  	<b class="tooltip tooltip-top-right"><i class="fa fa-lock txt-color-teal"></i> {{'aerial.login.insertCode'|translate}}</b> </label>
					<div class="note">
                  		<span class="aerial-login-code">{{codeInstructions|translate}}</span>
                	</div>
					<div style="padding-top: 20px">
                  		<div (click)="requestNewCode($event)"><span class="aerial-login-link">{{'aerial.login.requestCode'|translate}}</span></div>
                	</div>
					<div *ngIf="showRequestCodeResponse">
                  		<div class="aerial-login-error">{{'aerial.login.code.requested'|translate}}</div>
                	</div>
              </section>
				<section *ngIf="showError">
                	<div class="aerial-login-error">{{errorMsg|translate}}</div>
				</section>
            </fieldset>
            <footer  style="border:none;background-color:white!important"  *ngIf="!forgotPasswordMode">
              <button (click)="login($event)" class="btn btn-primary aerial-login-buttom" [disabled]="!enableBtn">
                {{'aerial.login.signIn'|translate}}
              </button>
            </footer>
			<footer  style="border:none;background-color:white!important"  *ngIf="forgotPasswordMode">
              <button (click)="requestNewPassword($event)" class="btn btn-primary aerial-login-buttom">
                {{'aerial.login.requestNewPassword'|translate}}
              </button>
            </footer>
          </form>
		</div>
		<!--
		<div class="note" style="position:absolute; top: auto; bottom: 10px">
			<a href="https://unsplash.com/es/fotos/fF8Cr_haCl8?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" target="_blank" class="aerial-login-reference">Foto de Stewart Edward en Unsplash</a>
		</div>
		-->
	</div>
</div>