<div class="aerial-component {{componentClass}}">
	<div class="col-md-12 aerial-component-header">
		<span><i class="material-icons">dark_mode</i> {{'aerial.user.sleep' | translate}}</span>
		<div class="pull-right">
			<button class="aerial-action-button af-item-fixed" *ngFor="let timeChartType of timeChartTypes" (click)="changeTimeChartType(timeChartType)" [class.selected]="timeChartType == selectedTimeChartType">
				{{timeChartType.label|translate}}
			</button>
		</div>
	</div>
	<div class="col-md-12 aerial-component-body" *ngIf="displayMode=='week' || displayMode=='month'">
		<div class="col-md-12">	
			<highcharts-chart *ngIf="options!=null" [Highcharts]="Highcharts"  [options]="options" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
		</div>
		<div class="col-md-12 aerial-flex af-align-items-center af-create-items-gap-5 af-justify-content-center">
				<button (click)="nextDay(false)" class="aerial-action-button">&lt;</button>
				<div class="aerial-action-button  aerial-flex af-create-items-gap-5 af-align-items-center">
					<span class="material-icons">calendar_today</span>
					<input type="text"
						bsDatepicker
						[(ngModel)]="todayDate"
						[bsConfig]="bsConfig"
						(ngModelChange)="changeWeeklyMonthlyDate($event)"
						style="border: 0;width: 8em;text-align: center;">
				</div>
				<button (click)="nextDay(true)" class="aerial-action-button">&gt;</button>
		</div>
		<div class="col-md-12" style="margin-top: 10px" *ngIf="sleepDaySummary">
			<aerial-user-sleep-daysummary [sleepDaySummaryInfo]="sleepDaySummary" [mode]="'normal'"></aerial-user-sleep-daysummary>
		</div>
	</div>
	<div class="col-md-8 aerial-component-body" *ngIf="displayMode=='day' && hasData()">
		<div class="col-md-12">
			<div class="col-md-12" style="margin-top: 10px">
				<aerial-user-sleep-daysummary [sleepDaySummaryInfo]="sleepTodayInfo"  [mode]="'timeline'"></aerial-user-sleep-daysummary>
			</div>
			<div class="col-md-12 aerial-flex af-align-items-center af-create-items-gap-5 af-justify-content-center">
				<button (click)="nextDay(false)" class="aerial-action-button">&lt;</button>
				<div class="aerial-action-button  aerial-flex af-create-items-gap-5 af-align-items-center">
					<span class="material-icons">calendar_today</span>
					<input type="text"
						bsDatepicker
						[(ngModel)]="todayDate"
						[bsConfig]="bsConfig"
						(ngModelChange)="changeTodayDate($event)"
						style="border: 0;width: 8em;text-align: center;">
				</div>
				<button (click)="nextDay(true)" class="aerial-action-button">&gt;</button>
			</div>
		</div>
		<div class="col-md-12" style="margin-top: 10px">
			<aerial-user-sleep-daysummary [sleepDaySummaryInfo]="sleepTodayInfo"  [mode]="'other'"></aerial-user-sleep-daysummary>
		</div>
	</div>
	<div class="col-md-8 aerial-component-body" *ngIf="displayMode=='day' && !hasData()" style="margin-top: 20px">
		<div class="col-md-12" style="margin-bottom: 20px">
			<span *ngIf="isDateToday()" class="aerial-alert-warning">{{'aerial.user.sleep.noDataToday' | translate}}</span>			
			<span *ngIf="!isDateToday()" class="aerial-alert-warning">{{'aerial.user.sleep.noData' | translate}}</span>
		</div>
		<div class="col-md-12 aerial-flex af-align-items-center af-create-items-gap-5 af-justify-content-center">
				<button (click)="nextDay(false)" class="aerial-action-button">&lt;</button>
				<div class="aerial-action-button  aerial-flex af-create-items-gap-5 af-align-items-center">
					<span class="material-icons">calendar_today</span>
					<input type="text"
						bsDatepicker
						[(ngModel)]="todayStr"
						[bsConfig]="bsConfig"
						(ngModelChange)="changeTodayDate($event)"
						style="border: 0;width: 8em;text-align: center;">
				</div>
				<button (click)="nextDay(true)" class="aerial-action-button">&gt;</button>
			</div>
	</div>
</div>