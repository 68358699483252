import {NgModule} from '@angular/core';

import {SharedModule} from '@app/shared/shared.module'
import { LayoutService } from '@app/core/services/layout.service';
import {AerialPortalRoutingModule} from './portal-routing.module';
//import {AerialPortalComponent} from './portal.component';
import {AerialDashboardModule} from './dashboard/dashboard.module';
import {AerialUsersModule} from './users/users.module'
import {AerialAdministratorsModule} from './administrators/administrators.module'


@NgModule({
  imports: [
    SharedModule,
    AerialPortalRoutingModule,
    AerialDashboardModule,
    AerialUsersModule,
    AerialAdministratorsModule
  ],
  declarations: [
    //AerialPortalComponent
  ],
  providers: [],
})
export class AerialPortalModule {
	constructor(private layoutService: LayoutService) {
		this.layoutService.onMenuOnTop();
	}
}
