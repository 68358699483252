<div id="content">
	 <sa-widgets-grid>
		<div class="row">
			<div class="col-md-12">
				<div class="col-md-12 aerial-table" style="height:auto">
					<span class="material-icons-outlined aerial-area-icon">admin_panel_settings</span> <span class="aerial-area-title">{{'aerial.menu.administrators' | translate}}</span>
					<a href="#/portal/administrators/details" class="aerial-add-button" style="float:right;"><div class="material-icons" style="padding-top:2px;">add</div><div style="float:right;padding-top:5px; padding-left: 5px">{{'aerial.new' | translate}}</div></a>
					<sa-datatable [options]="options"
                		filter="false" paginationLength="true"
                		tableClass="display projects-table table table-striped table-bordered table-hover" id="userListDataTable"
                		width="100%">
                		<thead>
	                		<tr>
		                  		<th class="hasinput">
		                    		<input type="text" class="form-control" placeholder="{{'aerial.firstName.placeholder' | translate}}">
		                  		</th>
		                  		<th class="hasinput">
		                    		<input type="text" class="form-control" placeholder="{{'aerial.lastName.placeholder' | translate}}">
		                  		</th>
								<th class="hasinput">
		                    		<input type="text" class="form-control" placeholder="{{'aerial.agent.account.placeholder' | translate}}">
		                  		</th>
								<th class="hasinput">
		                    		<input type="text" class="form-control" placeholder="{{'aerial.agent.email.placeholder' | translate}}">
		                  		</th>
								<th>&nbsp;</th>
							</tr>
							<tr>
		                  		<th data-class="expand" style="vertical-align:middle">{{'aerial.firstName' | translate }}</th>
		                  		<th style="vertical-align:middle">{{'aerial.lastName'|translate}}</th>
		                  		<th style="vertical-align:middle">{{'aerial.agent.account' | translate }}</th>
								<th style="vertical-align:middle">{{'aerial.email' | translate }}</th>
								<th style="vertical-align:middle">{{'aerial.status' | translate }}</th>
	                		</tr>
                		</thead>
					</sa-datatable>
				</div>
			</div>
		</div>
	</sa-widgets-grid>
</div>
