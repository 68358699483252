import { Component, OnInit } from '@angular/core';
import { AerialPingService } from './core/services/aerial.ping.service';
import { setTheme } from 'ngx-bootstrap/utils';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/store';
import { AuthChangePasswordRequired } from '@app/core/store/auth';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit{
  title = 'sa';
  
  constructor(private aerialPingService:AerialPingService, private localeService:BsLocaleService, private router: Router, private route: ActivatedRoute, private store:Store<AppState>){
  }
	
  ngOnInit(): void {
      setTheme("bs3");
      this.localeService.use('es');
      this.aerialPingService.getPingObservable().subscribe(
	      (response) => {
	        //console.log('Ping exitoso:', response);
	        if(response && response.hasToChangePassword==true){
				this.store.dispatch(new AuthChangePasswordRequired());
			}
			/*
	        this.route.url.subscribe(segments => {
		      const rutaActual = segments.map(segment => segment.path).join('/');
		      console.log('Ruta actual:', rutaActual);
		
		      if (rutaActual === 'ruta-especifica') {
		        console.log('Estás en la ruta específica');
		      }
		    });
	        this.router.navigate(['/portal']);
	        */
	      },
	      (error) => {
	        console.error('Error al hacer ping al servidor:', error);
	        //this.router.navigate(['/login']);
	      }
  );
  }
}



	