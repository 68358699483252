export {LayoutSwitcherComponent} from './layout-switcher.component';
export {SmartadminLayoutModule} from './layout.module';

export * from './footer'
//export * from './header'
export * from '../../aerial/layout/header'
export * from './navigation'
export * from './ribbon'
export * from './shortcut'


