

export type AerialUserSleepStepInfo = {
    label: number;
    activeTimeUnits: number;
    usualMinTimeUnits: number;
    usualMaxTimeUnits: number;
}

export type AerialUserSleepInfo ={
    firstDate: Date|string;
	detailList: AerialUserSleepStepInfo[];
	sleepDaySummary: AerialUserSleepTodayInfo;
}


import { ManipulateType } from "dayjs";
export interface TimeSleepChartType {
    label: string;
    querySelector: string;
    unitLabelToIncreaseOrDecrease:ManipulateType;
  }
  
export const TimeSleepChartTypes: Record<string, TimeSleepChartType> = {
    Day: { querySelector: 'daily', label: 'aerial.time.day',unitLabelToIncreaseOrDecrease:'day'},
    Week: { querySelector: 'weekly', label: 'aerial.time.week',unitLabelToIncreaseOrDecrease:'week'},
    Month: { querySelector: 'monthly', label: 'aerial.time.month',unitLabelToIncreaseOrDecrease:'month'},
  };

export type AerialSleepRangeType = {
	value: number;
	rangeLow: number;
	rangeHight: number;	
}

export type AerialUserSleepTodayInfo = {
	bedTime: AerialSleepRangeType,
	wakeUpTime: AerialSleepRangeType,
	sleepDuration: AerialSleepRangeType,
	interruptionData: AerialSleepRangeType
}