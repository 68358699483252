import {
  Component,
  Input,
  ElementRef,
  AfterContentInit,
  OnInit,
  SimpleChanges
} from "@angular/core";
import { HttpClient,HttpParams } from "@angular/common/http";
import { Subscription, interval } from 'rxjs';
import { OnDestroy, OnChanges } from "@angular/core";

import "script-loader!smartadmin-plugins/datatables/datatables.min.js";

@Component({
  selector: "sa-datatable",
  template: `
      <table class="dataTable responsive {{tableClass}}" width="{{width}}">
        <ng-content></ng-content>
      </table>
`,
  styles: ["smartadmin-plugins/datatables/datatables.min.css"]
})
export class DatatableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public options: any;
  @Input() public filter: any;
  @Input() public detailsFormat: any;

  @Input() public paginationLength: boolean;
  @Input() public columnsHide: boolean;
  @Input() public tableClass: string;
  @Input() public width: string = "100%";
  @Input() public refreshTime:number;	//AER-15

  constructor(private el: ElementRef) {}
 
 //AER-15
  dataTable:any;
  dataSubcription:Subscription;

	
  ngOnInit() {
    this.dataTable = this.render();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    	if(changes.refreshTime){
			this.startRefreshTable();
		}
	}
      
  ngOnDestroy() {
	    this.stopRefreshTable();
	  }
  
  private startRefreshTable(){
	  this.stopRefreshTable();
	  if(this.refreshTime){
		this.dataSubcription = interval(this.refreshTime).subscribe(() => {
	      this.refreshTableData();
	    });	
	}
  }  
  
  private stopRefreshTable(){
	  if (this.dataSubcription) {
	      this.dataSubcription.unsubscribe();
	    }
  }
  
  	private refreshTableData() {
		   this.dataTable.ajax.reload();
	  }
//AER-15

  render() {
    let element = $(this.el.nativeElement.children[0]);
    let options = this.options || {};

    let toolbar = "";
    if (options.buttons) toolbar += "B";
    if (this.paginationLength) toolbar += "l";
    if (this.columnsHide) toolbar += "C";

    if (typeof options.ajax === "string") {
      let url = options.ajax;
      options.ajax = {
        url: url
        // complete: function (xhr) {
        //
        // }
      };
    }

    options = $.extend(options, {
      dom:
        "<'dt-toolbar'<'col-xs-12 col-sm-6'f><'col-sm-6 col-xs-12 hidden-xs text-right'" +
        toolbar +
        ">r>" +
        "t" +
        "<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>",
      oLanguage: {
        sSearch:
          "<span class='input-group-addon'><i class='glyphicon glyphicon-search'></i></span> ",
        sLengthMenu: "_MENU_"
      },
      autoWidth: false,
      retrieve: true,
      responsive: true,
      initComplete: (settings, json) => {
        element
          .parent()
          .find(".input-sm")
          .removeClass("input-sm")
          .addClass("input-md");
      }
    });

    const _dataTable = element.DataTable(options);

    if (this.filter) {
      // Apply the filter
      element.on("keyup change", "thead th input[type=text]", function() {
        _dataTable
          .column(
            $(this)
              .parent()
              .index() + ":visible"
          )
          .search(this.value)
          .draw();
      });
    }

    if (!toolbar) {
      element
        .parent()
        .find(".dt-toolbar")
        .append(
          '<div class="text-right"><img src="assets/img/logo.png" alt="SmartAdmin" style="width: 111px; margin-top: 3px; margin-right: 10px;"></div>'
        );
    }

    if (this.detailsFormat) {
      let format = this.detailsFormat;
      element.on("click", "td.details-control", function() {
        var tr = $(this).closest("tr");
        var row = _dataTable.row(tr);
        if (row.child.isShown()) {
          row.child.hide();
          tr.removeClass("shown");
        } else {
          row.child(format(row.data())).show();
          tr.addClass("shown");
        }
      });
    }
    
    return _dataTable; //AER-15
  }
}
