import {NgModule} from '@angular/core';

import {SharedModule} from '@app/shared/shared.module'
import {LayoutService} from '@app/core/services/layout.service';
import {AerialDashboardRoutingModule} from './dashboard-routing.module';
import {AerialDashboardComponent} from './dashboard.component';
import {AerialCommonModule} from "@app/aerial/core/common/common.module";


@NgModule({
  imports: [
    SharedModule,
    AerialDashboardRoutingModule,
    AerialCommonModule
  ],
  declarations: [
    AerialDashboardComponent,
  ],
  providers: [],
})
export class AerialDashboardModule {
	constructor(private layoutService: LayoutService) {
		this.layoutService.onMenuOnTop();
	}
}
