import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

@Pipe({
  name: 'datetime',
  pure: false
})
export class AerialDateTimePipe implements PipeTransform {
  constructor() {}

  transform(value:number, format: string): string {
   		if(value==null){
		   return '';
	   	}else{
			const date = new Date(value);
   			return this.formatData(date, format);	   
		}
  }
  
  private formatData(date: Date, format:string){
	  
	if(format=='time'){
		return date.toLocaleTimeString();  
	  }else if(format=='date'){
		return date.toLocaleDateString();  
	  }else{
		  return date.toLocaleString();
	  }  
  }
}
