import { Component, Input, OnInit, ElementRef, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { AerialCommonModule } from '@app/aerial/core/common/common.module';
import { HttpClient,HttpParams } from "@angular/common/http";
import { UserCompleteInfo } from '@app/aerial/model/user.model';
import { SmartadminInputModule } from '@app/shared/forms/input/smartadmin-input.module';
import { convertirFechaATimestamp, convertirTimestampAFecha } from '@app/aerial/common/aerial.utils';

@Component({
  selector: 'aerial-user-meta-edit',
  templateUrl: './usermetaedit.component.html',
})

export class AerialUserMetaEditComponent implements OnInit, OnChanges {

	@Input() public userComplete: UserCompleteInfo;
	
	constructor(private el: ElementRef, private http:HttpClient) {}
	
	editable: boolean = false;
	buttonDisabled: boolean = false;
	floorList: any = [
		{name:'1',value:1},
		{name:'2',value:2},
		{name:'3',value:3},
		{name:'aerial.floor.moreThan3',value:4}	
	];
	petSizeList: any = [
		{name:'aerial.petSize.small',value:'small'},
		{name:'aerial.petSize.medium',value:'medium'},
		{name:'aerial.petSize.large',value:'large'}
	];
	sleepTimeList: number[] = [];
	wakeUpTimeList: number[] = [];
	timeZoneList: string[];
	
	vacationInitDate: string;
	vacationEndDate: string;
	
	ngOnInit() {
		this.initSleepTimeList();
		this.initwakeUpTimeList();
		this.initTimeZones();
		
		this.initVacationDates();
	}
	
	ngOnChanges(changes: SimpleChanges) {
		this.initVacationDates();
	}
	
	initVacationDates(){
		if(this.userComplete!=null && this.userComplete.vacation!=null){
			if(this.userComplete.vacation.initDate!=null){
				this.vacationInitDate = convertirTimestampAFecha(this.userComplete.vacation.initDate); 
			}
			if(this.userComplete.vacation.endDate!=null){
				this.vacationEndDate = convertirTimestampAFecha(this.userComplete.vacation.endDate); 
			}
		}
	}
	
	initTimeZones(){
		this.http.get("/api/rest/setup/timezones").subscribe(
			(data: string[]) => {
				this.timeZoneList=data;
			},
			(error) => {
				console.error('Error:', error);
			}
		);
	}
	
	initSleepTimeList(){
		let year = 1970;
		let month = 0;
		let day = 1;
		
		for (let i = 19; i <= 24; i++) {
		  this.sleepTimeList.push(new Date(year, month, day, i, 0).getTime());
		  this.sleepTimeList.push(new Date(year, month, day, i, 30).getTime());
		}
		
		for (let i = 1; i <= 3; i++) {
		  this.sleepTimeList.push(new Date(year, month, day, i, 0).getTime());
		  this.sleepTimeList.push(new Date(year, month, day, i, 30).getTime());
		}
	}
	
	initwakeUpTimeList(){
		let year = 1970;
		let month = 0;
		let day = 1;
		
		
		for (let i = 3; i <= 12; i++) {
		  this.wakeUpTimeList.push(new Date(year, month, day, i, 0).getTime());
		  this.wakeUpTimeList.push(new Date(year, month, day, i, 30).getTime());
		}
		for (let i = 13; i <= 19; i++) {
		  this.wakeUpTimeList.push(new Date(year, month, day, i, 0).getTime());
		  this.wakeUpTimeList.push(new Date(year, month, day, i, 30).getTime());
		}
		
	}
	
	public changeEditable(){
		this.editable = !this.editable;
	}
	
	public save(){
		this.buttonDisabled=true;
		this.http.post("/api/rest/user/save", this.userComplete).subscribe(
			(data: UserCompleteInfo) => {
				this.userComplete=data;
				this.editable=false;
				this.buttonDisabled=false;
			},
			(error) => {
				console.error('Error:', error);
				this.buttonDisabled=false;
			}
		);
	}
	
	public cancel(){
		this.buttonDisabled=true;
		this.http.get("/api/rest/user/"+this.userComplete.user.id).subscribe(
			(data: UserCompleteInfo) => {
				console.log(data);
				this.userComplete=data;
				this.editable=false;
				this.buttonDisabled=false;
			},
			(error) => {
				console.error('Error:', error);
				this.buttonDisabled=false;
			}
		);
	}
	
	public changeVacationInitDate($event){
		this.userComplete.vacation.initDate = convertirFechaATimestamp($event);
	}
	public changeVacationEndDate($event){
		this.userComplete.vacation.endDate = convertirFechaATimestamp($event);
	}
}
