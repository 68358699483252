import {NgModule} from '@angular/core';

import {SharedModule} from '@app/shared/shared.module'
import {LayoutService} from '@app/core/services/layout.service';
import {AerialUserDetailsRoutingModule} from './user.details-routing.module';
import {AerialUserDetailsComponent} from './user.details.component';
import {AerialCommonModule} from "@app/aerial/core/common/common.module";
import {SmartadminDatatableModule} from '@app/shared/ui/datatable/smartadmin-datatable.module';
import { AerialUserActivityComponent } from '../activity/user.activity.component';
import { AerialUserMetaComponent } from '../usermeta/usermeta.component';
import { AerialUserMetaEditComponent } from '../usermetaedit/usermetaedit.component';
import { AerialUserNotificationsComponent } from '../notifications/user.notifications.component';
import { SmartadminInputModule } from '@app/shared/forms/input/smartadmin-input.module';
import {HighchartsChartModule} from 'highcharts-angular';
import { AerialUserSleepDaySummaryComponent } from '../sleep/daysummary/daysummary.component';
import { AerialUserSleepComponent } from '../sleep/user.sleep.component';
import { AerialUserAlertsComponent } from '../alerts/user.alerts.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import "dayjs/locale/es";
import * as dayjs from 'dayjs';


@NgModule({
  imports: [
    SharedModule,
    AerialUserDetailsRoutingModule,
    AerialCommonModule,
    SmartadminDatatableModule,
    SmartadminInputModule,
    HighchartsChartModule,
    BsDatepickerModule.forRoot(),
  ],
  declarations: [
    AerialUserDetailsComponent,
    AerialUserActivityComponent,
    AerialUserMetaComponent,
    AerialUserMetaEditComponent,
    AerialUserNotificationsComponent,
    AerialUserSleepDaySummaryComponent,
    AerialUserSleepComponent,
    AerialUserAlertsComponent,
  ],
  providers: [],
})
export class AerialUserDetailsModule {
	constructor(private layoutService: LayoutService) {
		this.layoutService.onMenuOnTop();
    dayjs.locale("es");
	}
}
