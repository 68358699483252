import { Injectable } from '@angular/core';
import { HttpClient, HttpParams  } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AerialTranslationService {
  private translations: AerialTranslationInfo;
  
  constructor(private http: HttpClient) {
	  if(this.translations==null || this.translations == undefined) this.loadTranslations(null);
  }
	
  loadTranslations(lang: string) {
	  	const params = new HttpParams();
	  	if(lang!=null) params.set('lang', lang);
		this.http.get("/api/rest/i18n/translations", {params}).subscribe(
		  (data) => {
		    this.translations = data;
		  },
		  (error) => {
		    console.error('Error:', error);
		  }
		);
  }


  translate(key: string): string {
    if(this.translations == null || this.translations == undefined){
		return key;
	}else{
		return this.translations[key] || key;	
	}
  }

}

@Pipe({
  name: 'translate',
  pure: false
})
export class AerialTranslatePipe implements PipeTransform {
  constructor(private translationService: AerialTranslationService) {}

  transform(key: string): string {
   	return this.translationService.translate(key);
  }
}

export interface AerialTranslationInfo{
    
}



