import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AerialAdministratorsListComponent} from "./list/administrator.list.component";

const routes: Routes = [{
		path: '',
		component: AerialAdministratorsListComponent,
		data: {pageTitle: 'Administrators'}
	},
	{
		path: 'details',
		loadChildren:'./details/administrator.details.module#AerialAdministratorDetailsModule',
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AerialAdministratorsRoutingModule { }
