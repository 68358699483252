import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { AerialUserSleepTodayInfo} from '../user.sleep.data';

@Component({
  selector: 'aerial-user-sleep-daysummary',
  templateUrl: './daysummary.component.html',
})

export class AerialUserSleepDaySummaryComponent implements OnInit {

	@Input() public sleepDaySummaryInfo: AerialUserSleepTodayInfo;
	@Input() public mode: string; //normal or timeline


	constructor(private el: ElementRef) {
	}
		
	ngOnInit() {
		if(this.mode==null) this.mode='normal';
	}
}