import {Component, OnInit} from '@angular/core';
import {LoginInfoComponent} from "./../../../shared/user/login-info/login-info.component";


@Component({

  selector: 'aerial-navigation',
  templateUrl: './navigation.component.html'
})
export class AerialNavigationComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
