import {NgModule} from '@angular/core';

import {SharedModule} from '@app/shared/shared.module'
import {LayoutService} from '@app/core/services/layout.service';
import {AerialAdministratorDetailsRoutingModule} from './administrator.details-routing.module';
import {AerialAdministratorDetailsComponent} from './administrator.details.component';
import {AerialCommonModule} from "@app/aerial/core/common/common.module";
import {SmartadminDatatableModule} from '@app/shared/ui/datatable/smartadmin-datatable.module';
import { SmartadminInputModule } from '@app/shared/forms/input/smartadmin-input.module';



@NgModule({
  imports: [
    SharedModule,
    AerialAdministratorDetailsRoutingModule,
    AerialCommonModule,
    SmartadminDatatableModule,
    SmartadminInputModule
  ],
  declarations: [
    AerialAdministratorDetailsComponent
  ],
  providers: [],
})
export class AerialAdministratorDetailsModule {
	constructor(private layoutService: LayoutService) {
		this.layoutService.onMenuOnTop();
	}
}
