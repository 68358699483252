<ul class="header-dropdown-list hidden-xs ng-cloak">
  <li class="dropdown" dropdown>
    <a class="dropdown-toggle" dropdownToggle>
      <img
        src="assets/img/blank.gif"
        class="flag flag-{{currentLanguage.key}}"
        alt="{{currentLanguage.alt}}"
      />
      <span>&nbsp;{{ currentLanguage.title }}&nbsp;</span>

      <i class="fa fa-angle-down"></i>
    </a>
    <ul *dropdownMenu class="dropdown-menu">
      <li
        [class.active]="language == currentLanguage"
        *ngFor="let language of languages"
      >
        <a (click)="setLanguage(language)" class="dropdown-item"
          ><img
            src="assets/img/blank.gif"
            class="flag flag-{{language.key}}"
            alt="{{language.alt}}"
          />
          {{ language.title }}</a
        >
      </li>
    </ul>
  </li>
</ul>
