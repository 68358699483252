<aside id="left-panel">

  <nav>
    <!-- NOTE: Notice the gaps after each icon usage <i></i>..
    Please note that these links work a bit different than
    traditional href="" links. See documentation for details.
    -->

    <ul saSmartMenu style="height: 68px;background-color: white; padding-left: 30px">
		<!-- li class="aerial-nav-menu">
			<a title="{{'aerial.menu.dashboard' | translate}}" class="aerial-nav-menu-link" routerLink="/portal/dashboard"><i class="fa fa-lg fa-fw fa-home aerial-nav-menu-icon"></i> <span
          class="menu-item-parent aerial-nav-menu-text">{{'aerial.menu.dashboard' | translate}}</span></a>
		</li -->
		<li class="aerial-nav-menu" *aerialProfile="'userList'">
			<a title="{{'aerial.menu.users' | translate}}" class="aerial-nav-menu-link" routerLink="/portal/users"><i class="material-icons-outlined aerial-nav-menu-icon">location_away</i> <span
          class="menu-item-parent aerial-nav-menu-text">{{'aerial.menu.users' | translate}}</span></a>
		</li>
		<li class="aerial-nav-menu" *aerialProfile="'agentList'">
			<a title="{{'aerial.menu.administrators' | translate}}" class="aerial-nav-menu-link" routerLink="/portal/administrators"><i class="material-icons-outlined aerial-nav-menu-icon">admin_panel_settings</i> <span
          class="menu-item-parent aerial-nav-menu-text">{{'aerial.menu.administrators' | translate}}</span></a>
		</li>
      
	<!-- OLD
	<li>
        <a title="Dashboard"><i class="fa fa-lg fa-fw fa-home"></i> <span
          class="menu-item-parent">{{'Dashboard' | i18n}}</span></a>
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/dashboard/analytics">{{'Analytics Dashboard' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/dashboard/social">{{'Social Wall' | i18n}}</a>
          </li>
        </ul>
      </li>

      <li class="top-menu-invisible">
        <a href="#"><i class="fa fa-lg fa-fw fa-cube txt-color-blue"></i> <span class="menu-item-parent">{{'SmartAdmin Intel' | i18n}}</span></a>
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/smartadmin/app-layouts"><i class="fa fa-gear"></i>
              {{'App Layouts' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/smartadmin/prebuilt-skins"><i class="fa fa-picture-o"></i>
              {{'Prebuilt Skins' | i18n}}</a>
          </li>
        </ul>
      </li>

      <li routerLinkActive="active">
        <a routerLink="/outlook" title="Outlook">
          <i class="fa fa-lg fa-fw fa-inbox"></i> <span class="menu-item-parent">{{'Outlook' | i18n}}</span><span
          class="badge pull-right inbox-badge margin-right-13">14</span></a>
      </li>

      <li>
        <a href="#"><i class="fa fa-lg fa-fw fa-bar-chart-o"></i> <span
          class="menu-item-parent">{{'Graphs' | i18n}}</span></a>
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/graphs/flot-charts">{{'Flot Chart' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/graphs/morris-charts">{{'Morris Charts' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/graphs/sparklines">{{'Sparkline' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/graphs/easy-pie-charts">{{'Easy Pie Charts' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/graphs/dygraphs">{{'Dygraphs' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/graphs/chart-js">Chart.js</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/graphs/highchart-tables">Highchart Tables <span
              class="badge pull-right inbox-badge bg-color-yellow">new</span></a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#"><i class="fa fa-lg fa-fw fa-table"></i> <span
          class="menu-item-parent">{{'Tables' | i18n}}</span></a>
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/tables/normal">{{'Normal Tables' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/tables/datatables">{{'Data Tables' | i18n}} <span
              class="badge inbox-badge bg-color-greenLight">v1.10</span></a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/tables/ngx-datatable">{{'Ngx Datatable' | i18n}} <span
              class="badge inbox-badge bg-color-redLight">new</span></a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#"><i class="fa fa-lg fa-fw fa-pencil-square-o"></i> <span
          class="menu-item-parent">{{'Forms' | i18n}}</span></a>
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/forms/elements">{{'Smart Form Elements' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/forms/layouts">{{'Smart Form Layouts' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/forms/validation">{{'Smart Form Validation' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/forms/bootstrap-elements">{{'Bootstrap Form Elements' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/forms/bootstrap-validation">{{'Bootstrap Form Validation' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/forms/plugins">{{'Form Plugins' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/forms/wizards">{{'Wizards' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/forms/editors">{{'Bootstrap Editors' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/forms/dropzone">{{'Dropzone' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/cropper">{{'Image Cropping' | i18n}} <span
              class="badge pull-right inbox-badge bg-color-yellow">new</span></a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#"><i class="fa fa-lg fa-fw fa-desktop"></i> <span
          class="menu-item-parent">{{'UI Elements' | i18n}}</span></a>
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/ui/general">{{'General Elements' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/ui/buttons">{{'Buttons' | i18n}}</a>
          </li>
          <li>
            <a href="#">{{'Icons' | i18n}}</a>
            <ul>
              <li routerLinkActive="active">
                <a routerLink="/ui/icons/font-awesome"><i class="fa fa-plane"></i> {{'Font Awesome' | i18n}}</a>
              </li>
              <li routerLinkActive="active">
                <a routerLink="/ui/icons/glyphicons"><i class="glyphicon glyphicon-plane"></i>
                  {{'Glyph Icons' | i18n}}</a>
              </li>
              <li routerLinkActive="active">
                <a routerLink="/ui/icons/flags"><i class="fa fa-flag"></i> {{'Flags' | i18n}}</a>
              </li>
            </ul>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/ui/grid">{{'Grid' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/ui/tree-views">{{'Tree View' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/ui/nestable-lists">{{'Nestable Lists' | i18n}}</a>
          </li>
           <li routerLinkActive="active">
            <a routerLink="/ui/typography">{{'Typography' | i18n}}</a>
          </li>
          <li>
            <a href="#">{{'Six Level Menu' | i18n}}</a>
            <ul>
              <li>
                <a href="#"><i class="fa fa-fw fa-folder-open"></i> {{'Item #2' | i18n}}</a>
                <ul>
                  <li>
                    <a href="#"><i class="fa fa-fw fa-folder-open"></i> {{'Sub #2.1' | i18n}} </a>
                    <ul>
                      <li>
                        <a href="#"><i class="fa fa-fw fa-file-text"></i> {{'Item#2.1.1' | i18n}}</a>
                      </li>
                      <li>
                        <a href="#"><i class="fa fa-fw fa-plus"></i>{{'Expand' | i18n}}</a>
                        <ul>
                          <li>
                            <a href="#"><i class="fa fa-fw fa-file-text"></i>
                              {{'File' | i18n}}</a>
                          </li>
                          <li>
                            <a href="#"><i class="fa fa-fw fa-trash-o"></i>
                              {{'Delete' | i18n}}</a></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#"><i class="fa fa-fw fa-folder-open"></i> {{'Item #3' | i18n}}</a>

                <ul>
                  <li>
                    <a href="#"><i class="fa fa-fw fa-folder-open"></i> {{'3ed Level' | i18n}}
                    </a>
                    <ul>
                      <li>
                        <a href="#"><i class="fa fa-fw fa-file-text"></i>
                          {{'File' | i18n}}</a>
                      </li>
                      <li>
                        <a href="#"><i class="fa fa-fw fa-file-text"></i>
                          {{'File' | i18n}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>

              </li>
            </ul>
          </li>
        </ul>
      </li>

      <li routerLinkActive="active">
        <a routerLink="/widgets" title="Widgets"><i class="fa fa-lg fa-fw fa-list-alt"></i><span
          class="menu-item-parent">{{'Widgets' | i18n}}</span></a>
      </li>

      <li>
        <a href="#">
          <i class="fa fa-lg fa-fw fa-cloud"><em>2</em></i> <span
          class="menu-item-parent">{{'Cool Features' | i18n}}</span></a>
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/calendar" title="Calendar"><i
              class="fa fa-lg fa-fw fa-calendar"></i> <span
              class="menu-item-parent">{{'Calendar' | i18n}}</span></a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/maps"><i class="fa fa-lg fa-fw fa-map-marker"></i> <span class="menu-item-parent">{{'GMap Skins' | i18n}}</span><span
              class="badge bg-color-greenLight pull-right inbox-badge">9</span></a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#">
          <i class="fa fa-lg fa-fw fa-puzzle-piece"></i> <span
          class="menu-item-parent">{{'App Views' | i18n}}</span></a>
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/app-views/projects"><i class="fa fa-file-text-o"></i>
              {{'Projects' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/app-views/blog"><i class="fa fa-paragraph"></i> {{'Blog' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/app-views/gallery"><i class="fa fa-picture-o"></i>
              {{'Gallery' | i18n}}</a>
          </li>

          <li>
            <a href="#"><i class="fa fa-comments"></i> {{'Forum Layout' | i18n}}</a>
            <ul>
              <li routerLinkActive="active">
                <a routerLink="/app-views/forum/general-view"><i class="fa fa-picture-o"></i>
                  {{'General View' | i18n}}</a>
              </li>
              <li routerLinkActive="active">
                <a routerLink="/app-views/forum/topic-view"><i class="fa fa-picture-o"></i>
                  {{'Topic View' | i18n}}</a>
              </li>
              <li routerLinkActive="active">
                <a routerLink="/app-views/forum/post-view"><i class="fa fa-picture-o"></i>
                  {{'Post View' | i18n}}</a>
              </li>
            </ul>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/app-views/profile"><i class="fa fa-group"></i>
              {{'Profile' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/app-views/timeline"><i class="fa fa-clock-o"></i>
              {{'Timeline' | i18n}}</a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#">
          <i class="fa fa-lg fa-fw fa-shopping-cart"></i> <span
          class="menu-item-parent">{{'E-Commerce' | i18n}}</span></a>
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/e-commerce/orders" title="Orders"> {{'Orders' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/e-commerce/products-view" title="Products View"> {{'Products View' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/e-commerce/product-details" title="Products Detail"> {{'Products Detail' | i18n}}</a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#"><i class="fa fa-lg fa-fw fa-windows"></i> <span
          class="menu-item-parent">{{'Miscellaneous' | i18n}}</span></a>
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/miscellaneous/pricing-tables">{{'Pricing Tables' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/miscellaneous/invoice">{{'Invoice' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/auth/login">{{'Login' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/auth/register">{{'Register' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/auth/forgot-password">{{'Forgot Password' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/auth/locked">{{'Locked Screen' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/miscellaneous/error404">{{'Error 404' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/miscellaneous/error500">{{'Error 500' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/miscellaneous/blank">{{'Blank Page' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/miscellaneous/email-template">{{'Email Template' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/miscellaneous/search">{{'Search Page' | i18n}}</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/miscellaneous/ckeditor">{{'CK Editor' | i18n}}</a>
          </li>
        </ul>
      </li>

      <li aside-chat></li>
	END OLD -->

    </ul>

  </nav>

  <sa-minify-menu></sa-minify-menu>

</aside>
