<div class="col-md-12 aerial-device-data-content" *ngIf="deviceType=='IERTEC'">
	<div class="col-md-12 aerial-bottom-line" style="margin-top:10px">
		<div class="">
			<span class="material-icons-outlined aerial-area-icon-16">location_on</span><span class="aerial-area-icon-text-14">{{'aerial.user.device.location' | translate}}</span>
		</div>
		<div class="aerial-field" *ngIf="data.hasOwnProperty('zoneId')">
			<select  *ngIf="data.hasOwnProperty('zoneId')" [(ngModel)]="data.zoneId" [disabled]="!editable">
				 <option [value]="location.id" *ngFor="let location of thirdPartLocationList">{{ location.name }}</option>
			</select>
		</div>
	</div>	
	<div class="col-md-12" *ngIf="data.hasOwnProperty('doorLastUpdated') && data.doorLastUpdated">
		<div class="col-md-12" *ngIf="data.hasOwnProperty('doorLastUpdated') && data.doorLastUpdated">
			<div class="col-md-6 aerial-device-data-content-info">
				<div *ngIf="data.hasOwnProperty('doorOpen') && data.doorOpen==true"><span class="aerial-device-sensor-data active">{{'aerial.device.door.open'|translate}}</span></div>
				<div *ngIf="data.hasOwnProperty('doorOpen') && data.doorOpen==false"><span class="aerial-device-sensor-data inactive">{{'aerial.device.door.closed'|translate}}</span></div>
			</div>
			<div class="col-md-6 aerial-device-data-content-detail">
				<div *ngIf="data.hasOwnProperty('doorLastUpdated')" class="aerial-device-sensor-data">{{renderDate(data.doorLastUpdated)}}</div>
			</div>		
		</div>
	</div>
	<div class="col-md-12" *ngIf="data.hasOwnProperty('smokeLastUpdated') && data.smokeLastUpdated">
		<div class="col-md-12" *ngIf="data.hasOwnProperty('smokeLastUpdated') && data.smokeLastUpdated">
			<div class="col-md-6 aerial-device-data-content-info">
				<div *ngIf="data.hasOwnProperty('smokeDetected') && data.smokeDetected==true"><span class="aerial-device-sensor-data detected">{{'aerial.device.smoke.detected'|translate}}</span></div>
				<div *ngIf="data.hasOwnProperty('smokeDetected') && data.smokeDetected==false"><span class="aerial-device-sensor-data nondetected">{{'aerial.device.smoke.nonDetected'|translate}}</span></div>
			</div>
			<div class="col-md-6 aerial-device-data-content-detail">
				<div *ngIf="data.hasOwnProperty('smokeLastUpdated')" class="aerial-device-sensor-data">{{renderDate(data.smokeLastUpdated)}}</div>
			</div>		
		</div>
	</div>
	<div class="col-md-12" *ngIf="data.hasOwnProperty('gasLastUpdated') && data.gasLastUpdated">
		<div class="col-md-12" *ngIf="data.hasOwnProperty('gasLastUpdated') && data.gasLastUpdated">
			<div class="col-md-6 aerial-device-data-content-info">
				<div *ngIf="data.hasOwnProperty('gasDetected') && data.gasDetected==true"><span class="aerial-device-sensor-data detected">{{'aerial.device.gas.detected'|translate}}</span></div>
				<div *ngIf="data.hasOwnProperty('gasDetected') && data.gasDetected==false"><span class="aerial-device-sensor-data nondetected">{{'aerial.device.gas.nonDetected'|translate}}</span></div>
			</div>
			<div class="col-md-6 aerial-device-data-content-detail">
				<div *ngIf="data.hasOwnProperty('gasLastUpdated')" class="aerial-device-sensor-data">{{renderDate(data.gasLastUpdated)}}</div>
			</div>		
		</div>
	</div>
	<div class="col-md-12" *ngIf="data.hasOwnProperty('plugLastUpdated') && data.plugLastUpdated">
		<div class="col-md-12" *ngIf="data.hasOwnProperty('plugLastUpdated') && data.plugLastUpdated">
			<div class="col-md-6 aerial-device-data-content-info">
				<div *ngIf="data.hasOwnProperty('plugActive') && data.plugActive==true"><span class="aerial-device-sensor-data active">{{'aerial.device.plug.active'|translate}}</span></div>
				<div *ngIf="data.hasOwnProperty('plugActive') && data.plugActive==false"><span class="aerial-device-sensor-data inactive">{{'aerial.device.plug.inactive'|translate}}</span></div>
			</div>
			<div class="col-md-6 aerial-device-data-content-detail">
				<div *ngIf="data.hasOwnProperty('plugLastUpdated')" class="aerial-device-sensor-data">{{renderDate(data.plugLastUpdated)}}</div>
				<div *ngIf="data.hasOwnProperty('energy')"><span class="aerial-device-sensor-title">{{'aerial.device.sensor.energy'|translate}}: </span><span class="aerial-device-sensor-data small">{{data.energy}}</span></div>
				<div *ngIf="data.hasOwnProperty('current')"><span class="aerial-device-sensor-title">{{'aerial.device.sensor.current'|translate}}: </span><span class="aerial-device-sensor-data small">{{data.current}}</span></div>
				<div *ngIf="data.hasOwnProperty('voltage')"><span class="aerial-device-sensor-title">{{'aerial.device.sensor.voltage'|translate}}: </span><span class="aerial-device-sensor-data small">{{data.voltage}}</span></div>
			</div>		
		</div>
	</div>
	<div class="col-md-12" *ngIf="data.hasOwnProperty('lightLastUpdated') && data.lightLastUpdated">
		<div class="col-md-12" *ngIf="data.hasOwnProperty('lightLastUpdated') && data.lightLastUpdated">
			<div class="col-md-6 aerial-device-data-content-info">
				<div *ngIf="data.hasOwnProperty('lightActive') && data.lightActive==true"><span class="aerial-device-sensor-data active">{{'aerial.device.light.active'|translate}}</span></div>
				<div *ngIf="data.hasOwnProperty('lightActive') && data.lightActive==false"><span class="aerial-device-sensor-data inactive">{{'aerial.device.light.inactive'|translate}}</span></div>
			</div>
			<div class="col-md-6 aerial-device-data-content-detail">
				<div *ngIf="data.hasOwnProperty('lightLastUpdated')" class="aerial-device-sensor-data">{{renderDate(data.lightLastUpdated)}}</div>
			</div>
		</div>
	</div>
	<div class="col-md-12" *ngIf="data.hasOwnProperty('environmentLastUpdated') && data.environmentLastUpdated">
		<div class="col-md-12" *ngIf="data.hasOwnProperty('environmentLastUpdated') && data.environmentLastUpdated">
			<div class="col-md-6 aerial-device-data-content-info">
				&nbsp;			
			</div>
			<div class="col-md-6 aerial-device-data-content-detail">
				<div *ngIf="data.hasOwnProperty('environmentLastUpdated')" class="aerial-device-sensor-data">{{renderDate(data.environmentLastUpdated)}}</div>
				<div *ngIf="data.hasOwnProperty('temperature')"><span class="aerial-device-sensor-title">{{'aerial.device.sensor.temperature'|translate}}: </span><span class="aerial-device-sensor-data small">{{data.temperature}}</span></div>
				<div *ngIf="data.hasOwnProperty('humidity')"><span class="aerial-device-sensor-title">{{'aerial.device.sensor.humidity'|translate}}: </span><span class="aerial-device-sensor-data small">{{data.humidity}}</span></div>
			</div>		
		</div>
	</div>
</div>