import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/store';
import { LogoutAction } from '@app/core/store/auth';

declare var $: any;

@Component({
  selector: 'aerial-header',
  templateUrl: './header.component.html',
})
export class AerialHeaderComponent implements OnInit {
	
  constructor(private router: Router ,private store:Store<AppState>) {
  }

  ngOnInit() {
  }


  searchMobileActive = false;

  toggleSearchMobile(){
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }
  
  logout(){
	  this.store.dispatch(new LogoutAction());	
  }
  changePassword(){
	  this.router.navigate(['/login/changePassword']);	
  }
}
