<div class="col-md-12 aerial-device-box-header-container">
	<span [class.aerial-device-box-disconnected]="!device.connected" >
		<div [class.aerial-device-box-disconnected]="!device.connected" class="material-icons aerial-device-box-icon">podcasts</div>
		{{(device.connected?'aerial.device.connected':'aerial.device.disconnected') | translate}}
	</span>
	<div *ngIf="displayStatusMessage" style="display: inline-block;"><span [class.aerial-device-box-disconnected]="!device.connected"> ({{device.statusMessage}})</span></div>
	<button class="aerial-action-button pull-right" (click)="changeEditable()" *ngIf="!editable"><i class="material-icons-outlined aerial-color-grey aerial-action-button-icon">mode_edit</i></button>
	<button class="aerial-action-button pull-right" (click)="cancel()" *ngIf="editable"><i class="material-icons aerial-color-red aerial-action-button-icon">close</i></button>
	<button class="aerial-action-button pull-right" (click)="save()" *ngIf="editable"><i class="material-icons aerial-color-blue aerial-action-button-icon">done</i></button>	
	<div class="aerial-device-box-title-lastupdate" title="{{'aerial.device.lastUpdate' | translate}}"><span class="material-icons aerial-device-box-icon-lastupdate">update</span> {{renderStatusLastUpdate()}}</div>
</div>
<div class="col-md-12 aerial-device-box-body-container" style="padding-bottom:20px;padding-top:20px">
	<div class="col-md-4">
		<div class="aerial-device-img"><img src="{{deviceImage()}}"></div>
	</div>
	<div class="col-md-8">
			<span class="aerial-area-icon-text-14" [class.aerial-device-box-disconnected]="!device.connected" style="font-size:11px; padding-left: 0px;" >{{'aerial.device' | translate}}</span><br/>
			<span class="material-icons aerial-device-box-icon">devices</span>
			<span style="padding-left: 15px" [class.aerial-device-box-disconnected]="!device.connected" *ngIf="!editable">{{device.name}}</span><br/>
			<input type="text" [(ngModel)]="device.name" *ngIf="editable"><br/>
			<span class="aerial-sublabel">{{device.mac}}</span>
	</div>
	<div *ngIf="displayBatteryData()" class="col-md-12" style="margin-top:10px">
		<div class="col-md-4">
			&nbsp;
		</div>
		<div class="col-md-8" style=" margin-top: -10px; margin-left: -5px;">
			 <aerial-device-battery-data [data]="device.additionalData"></aerial-device-battery-data>	
		</div>
	</div>
	<div *ngIf="displaySensitivityAndLocation()" class="col-md-12 aerial-bottom-line">&nbsp;</div>
	<div *ngIf="displaySensitivityAndLocation()" class="col-md-12 aerial-bottom-line" style="margin-top:10px">
		<div class="">
			<span class="material-icons-outlined aerial-area-icon-16">location_on</span><span class="aerial-area-icon-text-14">{{'aerial.user.device.location' | translate}}</span>
		</div>
		<div class="aerial-field">
			<select [(ngModel)]="device.location" [disabled]="!editable">
				 <option [value]="location" *ngFor="let location of deviceLocationList">{{('aerial.device.location.' + location) | translate}}</option>
			</select>
		</div>
		<div class="aerial-field" *ngIf="device.location=='other'">
			<span class="aerial-sublabel">{{'aerial.device.location.otherCustom' | translate}}</span>
			<input type="text" [(ngModel)]="device.locationCustom" [disabled]="!editable">
		</div>
	</div>
	<div *ngIf="displaySensitivityAndLocation()" class="col-md-12" style="margin-top:10px">
		<div class="">
			<span class="material-icons-outlined aerial-area-icon-16">spoke</span><span class="aerial-area-icon-text-14">{{'aerial.user.device.sensibility' | translate}}</span><span class="badge aerial-bgcolor-blue txt-color-white small" style="margin-left: 10px;padding-left: 6px;">{{device.sensivity}}</span>
		</div>
		<div class="aerial-field text-center">
			<input type="range" min="1" max="5" [(ngModel)]="device.sensivity" class="aerial-slider-color"  [disabled]="!editable"> <!--  list="sensibilityDataList"  -->
			<!-- datalist id="sensibilityDataList">
				<option value="1" label="1"></option>
				<option value="2" label="2"></option>
				<option value="3" label="3"></option>
				<option value="4" label="4"></option>
				<option value="5" label="5"></option>
			</datalist -->
		</div>
	</div>
	<div *ngIf="displayAdditionalData()" class="col-md-12" style="margin-top:10px">
		<aerial-device-additional-data [data]="device.additionalData" [editable]="editable" [thirdPartLocationList]="thirdPartLocationList"></aerial-device-additional-data>
	</div>
</div>