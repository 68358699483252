import { Injectable, EventEmitter  } from '@angular/core';
import { HttpClient, HttpParams  } from '@angular/common/http';
import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, NgZone  } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AerialProfileService {
  private profile: AerialProfileInfo;
  stateUpdater = new EventEmitter<void>();
  
  constructor(private http: HttpClient) {
	  this.profile = null;
  }
	
  public setUpProfile(userProfile: AerialProfileInfo) {
	  if(!this.profile){
		this.profile = userProfile;
		this.stateUpdater.emit();  
	  }
  }
  
  public isAllowedViewMenu(menu: string): boolean{
	  let allowed = false;
	  if(this.profile && this.profile.profileMenuList){
		  for(var i=0;i<this.profile.profileMenuList.length;i++){
			  let profileMenuInfo: AerialProfileMenuInfo = this.profile.profileMenuList[i];
			  if(profileMenuInfo.code==menu){
				  allowed = true;
			  }
		  }
	  }
	  
	  return allowed;
  }

}

export interface AerialProfileInfo{
    profileMenuList: AerialProfileMenuInfo[]
}

export interface AerialProfileMenuInfo{
	code: string,
	description: string,
	displayOrder: number,
	iconCssClass: string,
	itemTypeId: string,
	menuPath: string,
	parent: string,
	purpose: string,
	text: string
}

@Directive({
  selector: '[aerialProfile]'
})

export class AerialProfileDirective implements OnInit{

	menu: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private profileService: AerialProfileService,
    private ngZone: NgZone
  ) {}
  
  ngOnInit() {
    this.profileService.stateUpdater.subscribe(() => {
      this.ngZone.run(() => {
        this.checkMenuAndDisplay(this.menu);
      });
    });
  }

  @Input() set aerialProfile(menu: string) {
	  this.menu = menu;
	  this.checkMenuAndDisplay(this.menu);	
  }
  
  checkMenuAndDisplay(menu:string){
		 if(!this.profileService.isAllowedViewMenu(menu)){
			this.viewContainer.clear();
		}else{
			this.viewContainer.createEmbeddedView(this.templateRef);
		} 
  }
}