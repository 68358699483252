import { Component, Input, OnInit, ElementRef} from '@angular/core';


@Component({
  selector: 'aerial-sleepbox',
  templateUrl: './sleepbox.component.html',
})

export class AerialSleepBoxComponent implements OnInit {

	//@Input() public deviceName: string;
	
	constructor(private el: ElementRef) {
	}
	
	ngOnInit() {
		
	}
}
