import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AerialUsersComponent} from "./users.component";

const routes: Routes = [{
		path: '',
		component: AerialUsersComponent,
		data: {pageTitle: 'Users'}
	},
	{
		path: 'details',
		loadChildren:'./details/user.details.module#AerialUserDetailsModule',
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AerialUsersRoutingModule { }
