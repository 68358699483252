<span class={{item.status}}>
  <a class="msg">
    <img src={{item.image}} alt="" class="air air-top-left margin-top-5" width="40"
         height="40"/>
    <span class="from">{{item.title}}</span>
    <span>{{item.time}}</span>
    <span class="subject">{{item.subject}}</span>
    <span class="msg-body">{{item.message}}</span>
  </a>
</span>
