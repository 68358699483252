<div class="col-xs-12 col-sm-5 col-md-5 col-lg-8" saSparklineContainer>
  <ul id="sparks" class="">
    <li class="sparks-info">
      <h5> My Income <span class="txt-color-blue">$47,171</span></h5>
      <div class="sparkline txt-color-blue hidden-mobile hidden-md hidden-sm">
        1300, 1877, 2500, 2577, 2000, 2100, 3000, 2700, 3631, 2471, 2700, 3631, 2471
      </div>
    </li>
    <li class="sparks-info">
      <h5> Site Traffic <span class="txt-color-purple"><i class="fa fa-arrow-circle-up"></i>&nbsp;45%</span></h5>
      <div class="sparkline txt-color-purple hidden-mobile hidden-md hidden-sm">
        110,150,300,130,400,240,220,310,220,300, 270, 210
      </div>
    </li>
    <li class="sparks-info">
      <h5> Site Orders <span class="txt-color-greenDark"><i class="fa fa-shopping-cart"></i>&nbsp;2447</span></h5>
      <div class="sparkline txt-color-greenDark hidden-mobile hidden-md hidden-sm">
        110,150,300,130,400,240,220,310,220,300, 270, 210
      </div>
    </li>
  </ul>
</div>
