import { Component, Input, OnInit, ElementRef} from '@angular/core';
import { AerialPingService } from '..//../../core/services/aerial.ping.service';

@Component({
  selector: 'aerial-integration',
  templateUrl: './integration.component.html',
})

export class AerialIntegrationComponent implements OnInit {

	//@Input() public deviceName: string;
	
	integrationErrorLevelList: AerialIntegrationInfo[];
	
	constructor(private el: ElementRef, private aerialPingService: AerialPingService) {
	}
	
	ngOnInit() {
		this.aerialPingService.getPingObservable().subscribe(
	      (response) => {
	        if(response && response.integrationErrorLevelMap){
				this.updateIntegrationdData(response.integrationErrorLevelMap);				
			}else{
				this.updateIntegrationdData(null);
			}
	  	})
	}
	
	private updateIntegrationdData(data: HashMapResponse){
		if(data==null){
			this.integrationErrorLevelList = [];
		}else{
			let temporalIntegrationList: AerialIntegrationInfo[] = [];
			for(const key in data){
				if(data.hasOwnProperty(key)){
					let integrationInfo = {
						name: key,
						level: data[key]
					}
					
					temporalIntegrationList.push(integrationInfo);
				}
			}
			
			this.integrationErrorLevelList = temporalIntegrationList;
		}
  	}
}

interface HashMapResponse{
	[key: string]: number;
}

export interface AerialIntegrationInfo{
    name: string,
    level: number
}

