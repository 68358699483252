import { Component, Input, OnInit, ElementRef } from '@angular/core';


@Component({
  selector: 'aerial-alertbudget',
  templateUrl: './alertbudget.component.html',
})

export class AerialAlertBudgetComponent implements OnInit {

	@Input() public budgetClass: string;
	@Input() public iconName: string;
	@Input() public iconClass: string;
	@Input() public alertAmount: string;
	@Input() public alertText: string;

	constructor(private el: ElementRef) {}
	
	ngOnInit() {
		this.iconName = this.iconName || ' ';
	}
}
