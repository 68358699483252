import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LayoutService } from '@app/core/services/layout.service';

@Component({
  selector: 'aerial-dashboard',
  templateUrl: './dashboard.component.html',
})
export class AerialDashboardComponent implements OnInit {
	constructor(private layoutService: LayoutService) {
		this.layoutService.onMenuOnTop();
	}
	
  ngOnInit() {
  }

}
