import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, interval, of, throwError, timer } from 'rxjs';
import { switchMap, repeat, catchError, shareReplay, takeUntil } from 'rxjs/operators';
import { Router} from '@angular/router';
import { AuthFailure } from '../store/auth';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/store';
import { AerialProfileService } from '@app/aerial/core/profile/profile.service';


@Injectable({
  providedIn: 'root'
})
export class AerialPingService{
  	private pingUrl = 'api/rest/login/ping'; 
  	private pingSubject = new BehaviorSubject<any>(null);
  	private failedPingCounter:number = 0;
  	
  	private MAX_FAILED_PING:number = 2;
  	private LOGIN_URL:string = '/login';
  	
  	private subscription: any;

  	constructor(private http: HttpClient, private router: Router, private store:Store<AppState>, private profileService: AerialProfileService) { 
		  this.startPing();
	  }

	 private startPing(): void {
	    timer(0, 5000).pipe(
	      switchMap(() => this.http.get(this.pingUrl).pipe(
	        catchError(error => {
	          console.error('Error haciendo ping:', error);
	          this.checkFailedPing();
	          return of(error); // Retorna el error sin completar el observable principal
	        })
	      ))
	    ).subscribe(response => {
			if(response && response.agent!=null) this.failedPingCounter=0;
			if(response && response.profile!=null) this.profileService.setUpProfile(response.profile);
			if(response && this.pingSubject) this.pingSubject.next(response);
		});
			
	  }
	
	  getPingObservable(): Observable<any> {
		return this.pingSubject.asObservable();
	  }
	  
	  private checkFailedPing(){
		  this.failedPingCounter++;
		  if(this.failedPingCounter>=this.MAX_FAILED_PING){
			  this.failedPingCounter=this.MAX_FAILED_PING;
			  			
			  const actualRoute = this.router.url;
			  if(actualRoute!=this.LOGIN_URL){
				  this.store.dispatch(new AuthFailure({error:'aerial.invalid.session'}));
			  }
		}
	  }
}
