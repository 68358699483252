import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { LayoutService } from '@app/core/services/layout.service';
import { UserDetailInfo } from '@app/shared/user/user.model';
import { HttpClient,HttpParams } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { AerialAgentInfo } from '../administrator.data';
import { Router } from '@angular/router';

@Component({
  selector: 'aerial-administrator-details',
  templateUrl: './administrator.details.component.html',
})
export class AerialAdministratorDetailsComponent implements OnInit {
	
	agentId:string=null;
	agentDetailInfo:AerialAgentInfo;
	
	editable: boolean = false;
	new: boolean = false;
	accountAlreadyUsed: boolean = false;
	notificationMsg: string;
	notificationWarning: boolean = false; 
	genericError: boolean = false;

	
	constructor(private layoutService: LayoutService, private http:HttpClient, private route: ActivatedRoute, private router: Router) {
		this.layoutService.onMenuOnTop();
	}
	
	
	ngOnInit() {	
		
		this.route.queryParams.subscribe(params => {
			this.agentId = params['id'];
	    });
		
		
		this.findAgentDetails();
	}
	
	
	findAgentDetails(){
		
		/* Esto es para los @QueryParams del Rest
		const params = new HttpParams();
	  	params.set('lang', lang);
		*/
		this.accountAlreadyUsed = false;
		this.notificationMsg = null;
		this.notificationWarning = false;
		
		if(this.agentId){
			this.http.get("/api/rest/agent/"+this.agentId).subscribe(
				(data: AerialAgentInfo) => {
					this.agentDetailInfo=data;
					this.editable = false;
				},
				(error) => {
					console.error('Error:', error);
				}
			);	
		}else{
			var agentInfo:AerialAgentInfo =  {
				id: null,
				firstName: '',
			    lastName: '',
			    fullName: '',
			    account: '',
			    email: '',
			    loginActive: false,
			    lastValidLogin: null,
			    loginInvalidRetries: null,
			    lastLoginIpAddress: null,
			    lastActivityIpAddress: null								
			};
			this.agentDetailInfo = agentInfo;
			this.editable = true;
			this.new = true;
		}
	}
	
	public changeEditable(){
		this.editable = !this.editable;
	}
	
	public save(){
		this.accountAlreadyUsed = false;
		this.genericError = false;
		
		var url:string = '/api/rest/agent/checkAccount?accountName='+ encodeURIComponent(this.agentDetailInfo.account);
		if(this.agentDetailInfo.id!=null) url += '&id=' + encodeURIComponent(this.agentDetailInfo.id); 
		
		this.http.get(url).subscribe(
				(data: boolean) => {
					if(data){
						this.saveAgent();
					}else{
						this.accountAlreadyUsed = true;
					}
				},
				(error) => {
					console.error('Error:', error);
					this.genericError = true;
				}
			);
	}
	
	public saveAgent(){
		var url:string =  '/api/rest/agent';
		if(this.agentId){
			url+='/'+ this.agentId;
		}else{
			url+='/save';
		}
		
		this.http.post(url, this.agentDetailInfo).subscribe(
				(data: AerialAgentInfo) => {
					this.agentDetailInfo=data;
					this.editable = false;
					this.new = false;
				},
				(error) => {
					console.error('Error:', error);
					this.genericError = true;
				}
			);
	}
	
	public cancel(){
		this.findAgentDetails();
	}
	
	public resetPassword(){
		var url:string = '/api/rest/agent/resetPassword/' + this.agentDetailInfo.id;
		this.notificationMsg = null;
		this.notificationWarning = false;
		
		this.http.get(url).subscribe(
				(data: boolean) => {
					if(data){
						this.notificationMsg = 'aerial.agent.resetPasswordSuccess';
					}else{
						this.notificationMsg = 'aerial.agent.resetPasswordFail';
						this.notificationWarning = true;
					}
				},
				(error) => {
					console.error('Error:', error);
				}
			);
	}

	public deleteAgent(){
		var url:string = '/api/rest/agent/' + this.agentDetailInfo.id;
		this.notificationMsg = null;
		this.notificationWarning = false;
		
		this.http.delete(url).subscribe(
				(data: boolean) => {
					if(data){
						this.notificationMsg = 'aerial.agent.deleteSuccess';
						this.router.navigate(['/portal/administrators']);
					}else{
						this.notificationMsg = 'aerial.agent.deleteFail';
						this.notificationWarning = true;
					}
				},
				(error) => {
					console.error('Error:', error);
				}
			);
	}	
	public enableLogin(){
		var url:string = '/api/rest/agent/enable/' + this.agentDetailInfo.id;
		this.notificationMsg = null;		
		this.notificationWarning = false;
		
		this.http.get(url).subscribe(
				(data: boolean) => {
					if(data){
						this.notificationMsg = 'aerial.agent.enableLoginSuccess';
						this.agentDetailInfo.loginActive = true;
					}else{
						this.notificationMsg = 'aerial.agent.enableLoginFail';
						this.notificationWarning = true;
					}
				},
				(error) => {
					console.error('Error:', error);
				}
			);
	}
	public disableLogin(){
		var url:string = '/api/rest/agent/disable/' + this.agentDetailInfo.id;
		this.notificationMsg = null;		
		this.notificationWarning = false;
		
		this.http.get(url).subscribe(
				(data: boolean) => {
					if(data){
						this.notificationMsg = 'aerial.agent.disableLoginSuccess';
						this.agentDetailInfo.loginActive = false;
					}else{
						this.notificationMsg = 'aerial.agent.disableLoginFail';
						this.notificationWarning = true;
					}
				},
				(error) => {
					console.error('Error:', error);
				}
			);
	}	
	public unlockAgent(){
		var url:string = '/api/rest/agent/unlock/' + this.agentDetailInfo.id;
		this.notificationMsg = null;		
		this.notificationWarning = false;
		
		this.http.get(url).subscribe(
				(data: boolean) => {
					if(data){
						this.notificationMsg = 'aerial.agent.unlockSuccess';
					}else{
						this.notificationMsg = 'aerial.agent.unlockFail';
						this.notificationWarning = true;
					}
				},
				(error) => {
					console.error('Error:', error);
				}
			);
	}
}