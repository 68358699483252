import {NgModule} from '@angular/core';

import {routing} from './aerial.routing';
import { SharedModule } from '@app/shared/shared.module';

import { AerialComponent } from './aerial.component';
import {LoginRoutingModule} from './login/login-routing.module'
import {AerialLoginModule} from './login/login.module'
import {AerialPortalModule} from './portal/portal.module'


@NgModule({
  declarations: [
    AerialComponent,
  ],
  imports: [
    SharedModule,
    routing,
    LoginRoutingModule,
    AerialLoginModule,
    AerialPortalModule
  ],
  providers: [],
  bootstrap: [AerialComponent]
})
export class AerialModule { }
