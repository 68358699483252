import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { LayoutService } from '@app/core/services/layout.service';
import { UserDetailInfo } from '@app/shared/user/user.model';
import { HttpClient,HttpParams } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import {RegisterHomeRequestInfo, RegisterHomeResponseInfo} from '../../../model/user.model';
import { ThirdPartLocationInfo } from '@app/aerial/model/user.model';

@Component({
  selector: 'aerial-user-details',
  templateUrl: './user.details.component.html',
})
export class AerialUserDetailsComponent implements OnInit {
	
	userId:string;
	userDetailInfo:any;
	section:string='details';
	
	createHome:boolean = false;
	detectDevices:boolean = false;
	registerHomeInfo:RegisterHomeRequestInfo;
	editableHub:boolean = false;
	registerHomeResponseInfo:RegisterHomeResponseInfo;
	showHubError:boolean = false;
	showGenericError:boolean=false;
	enableRegisterHomeBtn:boolean=true;
	discoverDevicesEnable:boolean = false;
	displayNoNewDevicesMsg:boolean = false;

	thirdPartLocationList:ThirdPartLocationInfo[];
	
	constructor(private layoutService: LayoutService, private http:HttpClient, private route: ActivatedRoute) {
		this.layoutService.onMenuOnTop();
	}
	
	
	ngOnInit() {
		
		this.route.queryParams.subscribe(params => {
			this.userId = params['id'];
			if(params['submenu']!=null && params['submenu']!=''){
				this.section = params['submenu'];
			}
	    });
		
		
		this.checkInitialData(); //AER-43
	}
	
	checkInitialData(){
		this.findUserDetails();
		this.checkCreateHome(); //AER-43
		this.checkDiscoverDevices(); //AER-43
		this.findThirdPartLocations(); //AER-43
	}
	
	
	findUserDetails(){
		
		/* Esto es para los @QueryParams del Rest
		const params = new HttpParams();
	  	params.set('lang', lang);
		*/
		
		this.http.get("/api/rest/user/"+this.userId).subscribe(
			(data) => {
				this.userDetailInfo=data;
			},
			(error) => {
				console.error('Error:', error);
			}
		);
	}
	
	public changeSubSection(subSection:string){
		this.section = subSection;
		this.displayNoNewDevicesMsg = false;
	}


	//AER-43
	checkCreateHome(){
		this.http.get("/api/rest/user/"+this.userId+"/canCreateHome").subscribe(
			(data:boolean) => {
				this.createHome=data;
			},
			(error) => {
				console.error('Error:', error);
			}
		);
	}
	
	public showCreateHome(){
		this.section = 'createHome';
		this.enableRegisterHomeBtn = true;
		this.editableHub=true;
		this.showHubError = false;
		this.showGenericError=false;
		this.registerHomeInfo = {
			userId: this.userId,
			hubMac: null
		}
	}
	
	public cancelRegisterHome(){
		this.editableHub=false;
		this.section = 'devices';
		this.registerHomeInfo = null;
		this.showHubError = false;
		this.showGenericError=false;
		this.enableRegisterHomeBtn = false;
	}
	
	public saveRegisterHome(){
		this.enableRegisterHomeBtn = false;
		this.showHubError = false;
		if(this.registerHomeInfo.hubMac!=null){
			this.http.post("/api/rest/user/"+this.userId+"/registerHome/", this.registerHomeInfo).subscribe(
				(data:RegisterHomeResponseInfo) => {
					this.enableRegisterHomeBtn = true;
					this.registerHomeResponseInfo = data;
					if(this.registerHomeResponseInfo!=null){
						if(this.registerHomeResponseInfo.errorMsg!=null){
							this.showHubError = true;
							this.showGenericError=false;
						}else{
							this.checkInitialData(); //AER-43
							this.section = "devices";
						}
					}else{
						this.showHubError = true;
						this.showGenericError=true;
					}					
				},
				(error) => {
					console.error('Error:', error);
					this.enableRegisterHomeBtn = true;
				}
			);	
		}
	}
	
	checkDiscoverDevices(){
		this.http.get("/api/rest/user/"+this.userId+"/canDiscoverDevices").subscribe(
			(data:boolean) => {
				this.discoverDevicesEnable=data;
			},
			(error) => {
				console.error('Error:', error);
			}
		);		
	}
	
	public discoverDevices(){
		this.displayNoNewDevicesMsg=false;
		this.http.get("/api/rest/user/"+this.userId+"/discoverDevices").subscribe(
			(data:boolean) => {
				if(data){
					this.findUserDetails();
				}else{
					this.displayNoNewDevicesMsg=true;
				}
			},
			(error) => {
				console.error('Error:', error);
			}
		);			
	}
	
	findThirdPartLocations(){
		if(this.userId){
			this.http.get("/api/rest/user/"+this.userId+"/thirdPartLocations/").subscribe(
				(data: ThirdPartLocationInfo[]) => {
					this.thirdPartLocationList=data;
				},
				(error) => {
					console.error('Error:', error);
				}
			);	
		}
	}	
	//AER-43	
}
