	<div class="col-md-12 aerial-component-body">
		<div class="col-md-12" style="margin-top: 10px" *ngIf="sleepDaySummaryInfo.sleepDuration && (mode=='normal' || mode=='other')">
			<div class="col-md-6">
				<span class="aerial-area-sleep-subtittle">{{'aerial.sleep.daily.time' | translate}}</span>
			</div>
			<div class="col-md-6">
				<div class="aerial-area-sleep-data"><span [ngClass]="{'aerial-alert-warning': (sleepDaySummaryInfo.sleepDuration.value<sleepDaySummaryInfo.sleepDuration.rangeLow || sleepDaySummaryInfo.sleepDuration.value>sleepDaySummaryInfo.sleepDuration.rangeHight)}">{{sleepDaySummaryInfo.sleepDuration.value | aerialHourMinuteFormat}}</span></div>
			</div>
		</div>
		<div class="col-md-12" *ngIf="sleepDaySummaryInfo.sleepDuration && (mode=='normal' || mode=='other')">
			<div class="col-md-6">
				<span class="aerial-area-sleep-range">{{'aerial.sleep.daily.range' | translate}}</span>
			</div>
			<div class="col-md-6">
				<span class="aerial-area-sleep-range">{{sleepDaySummaryInfo.sleepDuration.rangeLow | aerialHourMinuteFormat}} - {{sleepDaySummaryInfo.sleepDuration.rangeHight | aerialHourMinuteFormat}}</span>
			</div>	
		</div>
		<div class="col-md-12 aerial-line" *ngIf="sleepDaySummaryInfo.interruptionData && (mode=='normal' || mode=='other')">
		</div>
		<div class="col-md-12" *ngIf="sleepDaySummaryInfo.interruptionData && (mode=='normal' || mode=='other')">
			<div class="col-md-6">
				<span class="aerial-area-sleep-subtittle">{{'aerial.sleep.daily.interruptions' | translate}}</span>
			</div>
			<div class="col-md-6">
				<span class="aerial-area-sleep-data">{{sleepDaySummaryInfo.interruptionData.value}}</span>
			</div>	
		</div>
		<div class="col-md-12" *ngIf="sleepDaySummaryInfo.interruptionData && (mode=='normal' || mode=='other')">
			<div class="col-md-6">
				<span class="aerial-area-sleep-range">{{'aerial.sleep.daily.range' | translate}}</span>
			</div>
			<div class="col-md-6">
				<span class="aerial-area-sleep-range">{{sleepDaySummaryInfo.interruptionData.rangeLow | aerialHourMinuteFormat}} - {{sleepDaySummaryInfo.interruptionData.rangeHight | aerialHourMinuteFormat}}</span>
			</div>	
		</div>
		<div class="col-md-12 aerial-line" *ngIf="sleepDaySummaryInfo.bedTime && mode=='normal'">
		</div>
		<div class="col-md-12" *ngIf="sleepDaySummaryInfo.bedTime && mode=='normal'">
			<div class="col-md-6">
				<span class="aerial-area-sleep-subtittle">{{'aerial.sleep.daily.bedTime' | translate}}</span>
			</div>
			<div class="col-md-6">
				<div class="aerial-area-sleep-data"><span>{{sleepDaySummaryInfo.bedTime.value | datetime:'time'}}</span></div>
			</div>
		</div>
		<div class="col-md-12" *ngIf="sleepDaySummaryInfo.bedTime && mode=='normal'">
			<div class="col-md-6">
				<span class="aerial-area-sleep-range">{{'aerial.sleep.daily.range' | translate}}</span>
			</div>
			<div class="col-md-6">
				<span class="aerial-area-sleep-range">{{sleepDaySummaryInfo.bedTime.rangeLow | datetime:'time'}} - {{sleepDaySummaryInfo.bedTime.rangeHight | datetime:'time'}}</span>
			</div>	
		</div>
		<div class="col-md-12 aerial-line" *ngIf="sleepDaySummaryInfo.wakeUpTime && mode=='normal'">
		</div>
		<div class="col-md-12" *ngIf="sleepDaySummaryInfo.wakeUpTime && mode=='normal'">
			<div class="col-md-6">
				<span class="aerial-area-sleep-subtittle">{{'aerial.sleep.daily.wakeUpTime' | translate}}</span>
			</div>
			<div class="col-md-6">
				<div class="aerial-area-sleep-data"><span>{{sleepDaySummaryInfo.wakeUpTime.value | datetime:'time'}}</span></div>
			</div>
		</div>
		<div class="col-md-12" *ngIf="sleepDaySummaryInfo.wakeUpTime && mode=='normal'">
			<div class="col-md-6">
				<span class="aerial-area-sleep-range">{{'aerial.sleep.daily.range' | translate}}</span>
			</div>
			<div class="col-md-6">
				<span class="aerial-area-sleep-range">{{sleepDaySummaryInfo.wakeUpTime.rangeLow | datetime:'time'}} - {{sleepDaySummaryInfo.wakeUpTime.rangeHight | datetime:'time'}}</span>
			</div>	
		</div>
		<!-- div class="col-md-12 aerial-line" *ngIf="mode=='timeline'">
		</div -->
		<div class="col-md-12" style="margin-top: 10px; padding-bottom: 10px" *ngIf="mode=='timeline'">
			<div class="col-md-6">
				<span class="aerial-area-sleep-title">{{'aerial.sleep.daily.timeline' | translate}}</span>
			</div>			
		</div>
		<div class="col-md-12" *ngIf="mode=='timeline' && sleepDaySummaryInfo.bedTime!=null && sleepDaySummaryInfo.wakeUpTime!=null">
			<div class="col-md-1 aerial-area-sleep-subtittle" style="padding-bottom: 10px"><span class="material-icons-outlined">bedtime</span></div>
			<div class="col-md-11 aerial-area-sleep-data" style="margin-top: 4px;">
				<span *ngIf="sleepDaySummaryInfo.bedTime.value!=null" [ngClass]="{'aerial-alert-warning': (sleepDaySummaryInfo.bedTime.value<sleepDaySummaryInfo.bedTime.rangeLow || sleepDaySummaryInfo.bedTime.value>sleepDaySummaryInfo.bedTime.rangeHight)}">{{sleepDaySummaryInfo.bedTime.value | datetime:'time'}}</span>
				<span *ngIf="sleepDaySummaryInfo.bedTime.value==null">&nbsp;</span>
			</div>
			<div class="col-md-1">
				<div class="aerial-timeline-line" style="margin-left: 10px"></div>
			</div>
			<div class="col-md-11 aerial-area-sleep-data" style="height: 77px; padding-top: 0px;">
				<div style="margin-top: -15px"><span class="aerial-area-sleep-subtittle">{{'aerial.sleep.daily.bedTime' | translate}}</span></div>
				<div *ngIf="sleepDaySummaryInfo.wakeUpTime.value!=null" style="margin-top: 55px"><span [ngClass]="{'aerial-alert-warning': (sleepDaySummaryInfo.wakeUpTime.value<sleepDaySummaryInfo.wakeUpTime.rangeLow || sleepDaySummaryInfo.wakeUpTime.value>sleepDaySummaryInfo.wakeUpTime.rangeHight)}">{{sleepDaySummaryInfo.wakeUpTime.value | datetime:'time'}}</span></div>
				<div *ngIf="sleepDaySummaryInfo.wakeUpTime.value==null"><span>&nbsp;</span></div>
			</div>
			<div class="col-md-1 aerial-area-sleep-subtittle"><span class="material-icons-outlined">alarm</span></div>
			<div class="col-md-11 aerial-area-sleep-data" style="margin-top: 4px;">
				<span class="aerial-area-sleep-subtittle">{{'aerial.sleep.daily.wakeUpTime' | translate}}</span>
			</div>
		</div>
	</div>