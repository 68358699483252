import { Component, Input, OnInit, ElementRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import "script-loader!highcharts";
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import {AerialUserSleepInfo, AerialUserSleepTodayInfo, TimeSleepChartType, TimeSleepChartTypes} from './user.sleep.data';
import { HttpClient } from '@angular/common/http';
import { AerialTranslationService } from '@app/aerial/core/translation/translation.service';
import { convertirFechaATimestamp, convertirTimestampAFecha } from '@app/aerial/common/aerial.utils';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as dayjs from 'dayjs';

HC_more(Highcharts);



//format time in millis to h:mm
function formatTimeFromMillis(time: number): string {
	// Calcula las horas, minutos y segundos a partir de los milisegundos
	const totalSeconds = Math.floor(time / 1000);
	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
  
	// Formatea las horas y minutos en el formato deseado
	const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  
	return `${formattedHours}:${formattedMinutes}`;
}

function simpleSeriePointFormatter(){
	let point:Highcharts.Point=this;
	return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' +formatTimeFromMillis(point.y)+'<br>';
}

function usualSeriePointFormatter(){
	let point:Highcharts.Point=this;
	return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' +formatTimeFromMillis(point.low)+" - "+formatTimeFromMillis(point.high)+'<br>';
}


@Component({
  selector: 'aerial-user-sleep',
  templateUrl: './user.sleep.component.html',
})
export class AerialUserSleepComponent implements OnInit, OnDestroy, OnChanges{

	@Input() public componentClass: string;
	@Input() public userId: number;
	readonly timeChartTypes=[TimeSleepChartTypes.Day,TimeSleepChartTypes.Week,TimeSleepChartTypes.Month]
	selectedTimeChartType=TimeSleepChartTypes.Week;	
	
	Highcharts: typeof Highcharts = Highcharts; // required
	options: Highcharts.Options;
	lengthSelector: string;
	
	displayMode:string = 'week';
	sleepTodayInfo: AerialUserSleepTodayInfo;
	//todayStr: string;
	todayDate: Date;
	sleepDaySummary: AerialUserSleepTodayInfo;
	bsConfig: Partial<BsDatepickerConfig>;
	
		

	constructor(private el: ElementRef, private http: HttpClient, private translate:AerialTranslationService, private bsLocaleService:BsLocaleService) {
		bsLocaleService.use('es');
		this.el=el;
		this.bsConfig = {
			dateInputFormat: 'DD/MM/YYYY',
			containerClass: 'theme-dark-blue',
			showWeekNumbers:false,
			
			
		};
	}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['userId']){
			this.updateSleepInfo();
			
		}
    }
    ngOnDestroy(): void {
      
    }
	
	ngOnInit() {
		this.displayMode='week';
		this.todayDate = new Date(); 
				
		this.lengthSelector='weekly';
		
		this.updateSleepInfo();
	}
	
	
	updateSleepInfo(){
		var actualDate:Date = new Date(this.todayDate);
		if (this.userId!=null && actualDate!=null && this.lengthSelector!=null){
			let fechaFormateada = actualDate.toISOString().split('T')[0];
				this.http.get(`/api/rest/user/${this.userId}/sleep/${this.lengthSelector}/${fechaFormateada}`).subscribe((result:AerialUserSleepInfo)=>{
					if (typeof (result.firstDate)=='string'){
						result.firstDate = new Date(result.firstDate);
						this.sleepDaySummary = result.sleepDaySummary;
						this.todayDate = result.firstDate;
					}

					this.updateChart(result);
				})
		}else{
			this.updateChart(null);
		}
	}
	
	updateChart(userSleepInfo:AerialUserSleepInfo){
		this.options = {
		  chart: {
		    type: 'column',
		  },
		  title: {
		    text: null,
		  },
		  series: [
			  
		  ],
		  yAxis:{
			  type: 'datetime',
			  "dateTimeLabelFormats": { // don't display the dummy year
			    "minute": '%H:%M',
			    "hour": '%H:%M',
			    "day": '%H:%M'
			  },
			  title: {
				  text: null
			  }
		  },
		  xAxis: {
			  type: 'datetime',
			  minPadding: 0.1,
			  maxPadding: 0.1
		  },
		  legend:{
			  verticalAlign: 'top'
		  }, 
		  tooltip: {
			  shared: true
		  },
		  plotOptions: {
			  column: {
			    pointPadding: 0,
			    grouping: false,
			   	 
			  }
		  },
		  credits: {
		      enabled: false
		  }
		};
		let usualSerie={
			marker: {
				enabled: false
			},
			name: this.translate.translate('aerial.user.usualRange'),
			color: 'rgba(77, 55, 177,0.3)',
			opacity: 0.3,
			type: 'areasplinerange',
			legendIndex:3,
			pointPlacement: 'on',
			data:[],
			tooltip: {
				pointFormatter: usualSeriePointFormatter
			}
			
		} as  Highcharts.SeriesAreasplinerangeOptions
		this.options.series.push(usualSerie);
		
		let activeSerie={
			data:[],
			name: this.translate.translate('aerial.user.sleepHours'),
			color: '#4d35b7',
			legendIndex:1,
			pointPlacement: 'on',
			tooltip: {
				pointFormatter: simpleSeriePointFormatter
			}
		}as Highcharts.SeriesColumnOptions;
		this.options.series.push(activeSerie);
		
		let activeLowSerie={
			data:[],
			name: this.translate.translate('aerial.user.inusual'),
			color: '#f9744d',
			legendIndex:2,
			pointPlacement: 'on',
			tooltip: {
				pointFormatter: simpleSeriePointFormatter
			}
		}as Highcharts.SeriesColumnOptions;
		this.options.series.push(activeLowSerie);
		
		
		if (userSleepInfo){
			userSleepInfo.detailList.forEach(detail=>{
				let currentDate=new Date(userSleepInfo.firstDate);
				currentDate.setDate(currentDate.getDate()+detail.label);
				usualSerie.data.push([currentDate.getTime(),detail.usualMinTimeUnits*60000, detail.usualMaxTimeUnits*60000]);
				let dataPoint=[currentDate.getTime(),detail.activeTimeUnits*60000];
				if (detail.usualMinTimeUnits > detail.activeTimeUnits){
					activeLowSerie.data.push(dataPoint);
				}else{
					activeSerie.data.push(dataPoint);
				}
			});
		}
	}
	
	changeTimeChartType(timeChartType:TimeSleepChartType){
		if (this.selectedTimeChartType!=timeChartType){
			this.selectedTimeChartType = timeChartType;
			if(this.selectedTimeChartType==TimeSleepChartTypes.Day){
				this.displayMode='day';
				this.loadTodaySleepInfo(this.todayDate);
			}else if(this.selectedTimeChartType==TimeSleepChartTypes.Week){
				this.displayMode='week';
				this.lengthSelector=TimeSleepChartTypes.Week.querySelector;
				this.updateSleepInfo();
			}else{
				this.displayMode='month';
				this.lengthSelector=TimeSleepChartTypes.Month.querySelector;
				this.updateSleepInfo();
			}
		}
	}
	
	loadTodaySleepInfo(date){
		if (this.userId!=null){
				var url = '/api/rest/user/' + this.userId + '/sleep/today';
				if(date!=null) url += '/' + date.getTime(); 
				this.http.get(url).subscribe((result:AerialUserSleepTodayInfo)=>{
					this.sleepTodayInfo = result;
			})
		}else{
			this.updateChart(null);
		}
	}
	
	changeTodayDate($event){
		this.todayDate = this.calculateDateToCheck($event);
		this.loadTodaySleepInfo(this.todayDate);
	}
	
	changeWeeklyMonthlyDate($event){
		this.updateSleepInfo();
	}
	
	private calculateDateToCheck(date):Date{
		if (date==null){
			date = new Date();
		}
		return dayjs(date).startOf(this.selectedTimeChartType.unitLabelToIncreaseOrDecrease).toDate();
	}
	
	nextDay(addDay:boolean){
		if(addDay){
			this.todayDate=dayjs(this.todayDate).add(1,this.selectedTimeChartType.unitLabelToIncreaseOrDecrease).toDate();
		}else{
			this.todayDate=dayjs(this.todayDate).subtract(1,this.selectedTimeChartType.unitLabelToIncreaseOrDecrease).toDate();
		}
		if(this.displayMode=='day'){
			this.loadTodaySleepInfo(this.todayDate);	
		}else{
			this.updateSleepInfo();
		}
		
	}
	
	hasData(){
		var data: boolean = false;
		
		if(this.sleepTodayInfo!=null){
			if(this.sleepTodayInfo.bedTime!=null && this.sleepTodayInfo.wakeUpTime!=null){
				data = true;
			}
		}
		
		return data;
	}
	
	isDateToday(){
		var isToday = false;
		
		var actualDate:Date = new Date(this.todayDate);
		var todayDate: Date = new Date();
		isToday = (actualDate.getDay()==todayDate.getDay() && actualDate.getMonth()==todayDate.getMonth() && actualDate.getFullYear()==todayDate.getFullYear());
			
		return isToday;
	}
}
