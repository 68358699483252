import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgModule} from "@angular/core";


import {CollapseMenuComponent} from "../../../shared/layout/header/collapse-menu/collapse-menu.component";
import {RecentProjectsComponent} from "../../../shared/layout/header/recent-projects/recent-projects.component";
import {FullScreenComponent} from "../../../shared/layout/header/full-screen/full-screen.component";

import {ActivitiesComponent} from "../../../shared/layout/header/activities/activities.component";
import {ActivitiesMessageComponent} from "../../../shared/layout/header/activities/activities-message/activities-message.component";
import {ActivitiesNotificationComponent} from "../../../shared/layout/header/activities/activities-notification/activities-notification.component";
import {ActivitiesTaskComponent} from "../../../shared/layout/header/activities/activities-task/activities-task.component";
import {AerialHeaderComponent} from "./header.component";
import {AerialCommonModule} from "@app/aerial/core/common/common.module";

import {UtilsModule} from "@app/shared/utils/utils.module";
import {PipesModule} from "@app/shared/pipes/pipes.module";
import {I18nModule} from "@app/shared/i18n/i18n.module";
import {UserModule} from "@app/shared/user/user.module";
import {VoiceControlModule} from "@app/shared/voice-control/voice-control.module";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import { PopoverModule } from "ngx-bootstrap/popover";



@NgModule({
  imports: [
    CommonModule,

    FormsModule,

    VoiceControlModule,

    BsDropdownModule,

    UtilsModule,PipesModule, I18nModule, UserModule, PopoverModule,
    AerialCommonModule,
  ],
  declarations: [
    ActivitiesMessageComponent,
    ActivitiesNotificationComponent,
    ActivitiesTaskComponent,
    RecentProjectsComponent,
    FullScreenComponent,
    CollapseMenuComponent,
    ActivitiesComponent,
    AerialHeaderComponent,
  ],
  exports: [
    AerialHeaderComponent
  ]
})
export class AerialHeaderModule{}
