<div class="aerial-fullscreen-div ">
	<div class="aerial-login-bg" style="flex: 1; min-height: 1700px; background-size: 100%!important">
		<div class="well no-padding aerial-change-password">
			<form class="smart-form client-form"  style="border:none; margin-right:auto; margin-left: auto; width:75%">
            <header class="aerial-login-header">
              {{'aerial.login.changePassword'|translate}}
            </header>
            <fieldset  style="border:none">
              <section>
                <label class="label aerial-login-label">{{'aerial.login.oldPassword'|translate}}</label>
                <label class="input"> <i class="icon-append fa fa-lock"></i>
                  <input type="password" name="oldPassword" data-smart-validate-input="" required="" data-minlength="3" [(ngModel)]="changePasswordInfo.oldPassword">
				</label>
              </section>  
			  <section>
                <label class="label aerial-login-label">{{'aerial.login.newPassword'|translate}}</label>
                <label class="input"> <i class="icon-append fa fa-lock"></i>
                  <input type="password" name="newPassword" data-smart-validate-input="" required="" data-minlength="3" [(ngModel)]="changePasswordInfo.newPassword">
                </label>
              </section>
			  <section>
                <label class="label aerial-login-label">{{'aerial.login.repeat.newPassword'|translate}}</label>
                <label class="input"> <i class="icon-append fa fa-lock"></i>
                  <input type="password" name="repeatedPassword" data-smart-validate-input="" required="" data-minlength="3" [(ngModel)]="changePasswordInfo.repeatNewPassword">
                </label>
              </section>
			  <section *ngIf="invalidMsg">
				<div class="aerial-login-error" >{{invalidMsg|translate}}</div>
			  </section>
			  <section *ngIf="passwordErrorInfo">
                <div class="aerial-login-error" >
					<span>{{'aerial.changePassword.errors' | translate}}:</span>
					<ul style="padding-left:15px">
						<li *ngFor="let error of passwordErrorInfo.parameterViolations">{{error.message|translate}}</li>
					</ul>
				</div>
              </section>
            </fieldset>
            <footer  style="border:none;background-color:white!important">
              <button (click)="changePassword($event)" class="btn btn-primary aerial-login-buttom">
                {{'aerial.login.change'|translate}}
              </button>
            </footer>
          </form>
		</div> 	
	</div>	
</div>