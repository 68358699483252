import { Component, Input, OnInit, ElementRef, OnDestroy, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import "script-loader!highcharts";
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import {AerialUserActivityInfo, TimeActivityChartType,TimeActivityChartTypes} from './user.activity.data';
import { HttpClient } from '@angular/common/http';
import * as dayjs from 'dayjs';
import { AerialTranslationService } from '@app/aerial/core/translation/translation.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';



HC_more(Highcharts);




const activeColor='#0066ff';
const unusualColor='#d10000';
const usualColor="rgba(153, 153, 153,0.3)";

@Component({
	selector: 'aerial-user-activity',
	templateUrl: './user.activity.component.html',
  })
export class AerialUserActivityComponent implements OnInit, OnDestroy, OnChanges,AfterViewInit {



	@Input() public componentClass: string;
	@Input() public userId: number;
	readonly timeChartTypes=[TimeActivityChartTypes.Day,TimeActivityChartTypes.Week,TimeActivityChartTypes.Month]
	selectedTimeChartType=TimeActivityChartTypes.Day;
	
	Highcharts: typeof Highcharts = Highcharts; // required
	highchartsOptions: Highcharts.Options;
	highchartsUpdate=false;
	dateToQuery: Date = null;
	chartElement: any;
	highchartsObject: Highcharts.Chart;
	bsConfig: Partial<BsDatepickerConfig>;
	userActivityInfo: AerialUserActivityInfo;

	constructor(private el: ElementRef, private http: HttpClient, private translate:AerialTranslationService, private bsLocaleService:BsLocaleService) {
		bsLocaleService.use('es');
		
		this.el=el;
		this.bsConfig = {
			dateInputFormat: 'DD/MM/YYYY',
			containerClass: 'theme-dark-blue',
			showWeekNumbers:false,
			
			
		};
	}
	ngAfterViewInit(): void {
		// Buscar el elemento con la clase "highcharts-chart"
		this.chartElement = this.el.nativeElement.querySelector('.highcharts-chart');
		this.queryChart();
	}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['userId']){
			this.queryChart();
		}
    }
    ngOnDestroy(): void {
       /* if (this.chart!=null){
			this.chart.destroy();
		}*/
    }
	
	ngOnInit() {
		
	}
	
	queryChart(){
		if (this.selectedTimeChartType && this.userId && this.chartElement){
			this.calculateDateToCheck();
			let formattedDate=dayjs(this.dateToQuery).format('YYYY-MM-DD');
			let currentDate=this.dateToQuery;
			let currentTimeChartType=this.selectedTimeChartType;
			let currentUserId=this.userId;
			this.http.get(`/api/rest/user/${this.userId}/activity/${this.selectedTimeChartType.querySelector}/${formattedDate}`).subscribe((result:AerialUserActivityInfo)=>{
				if (this.dateToQuery==currentDate && currentTimeChartType==this.selectedTimeChartType && currentUserId == this.userId){
					this.updateChart(result);
				}
			})
		}
	}
	
	private calculateDateToCheck(){
		if (this.dateToQuery==null){
			this.dateToQuery = new Date();
		}
		//dayjs.locale(navigator.language);
		this.dateToQuery=dayjs(this.dateToQuery).startOf(this.selectedTimeChartType.unitLabelToIncreaseOrDecrease).toDate();
	}
	updateChart(userActivityInfo:AerialUserActivityInfo){
		if (this.highchartsObject){
			this.highchartsObject.destroy();
			this.highchartsObject=null;
		}
		dayjs.locale(navigator.language);
		this.highchartsOptions = {
		  chart: {
		    type: 'column',
		  },
		  title: {
		    text: null,
		  },
		  series: [
			  
		  ],
		  yAxis:{
			  //max: 60,
			  title: {
				  text: null
			  }
		  },
		  xAxis: {
				categories:[],
				/*type:'datetime',
				dateTimeLabelFormats: {
					day: '%e. %b'
				},*/
			  /*min :0,
			  max: 23,
			  minPadding: 0.1,
			  maxPadding: 0.1*/
		  },
		  legend:{
			  verticalAlign: 'top',
			  align: 'left'
		  }, 
		  tooltip: {
			  shared: true
		  },
		  plotOptions: {
			  /*column: {
			    pointPadding: 0,
			    grouping: false,
			   	 
			  }*/
		  },
		  credits: {
		      enabled: false
		  }
		};
		let usualSerie={
			marker: {
				enabled: false
			},
			id: 'aerial.user.usualRange',
			name: this.translate.translate('aerial.user.usualRange'),
			color: usualColor,
			opacity: 0.3,
			type: 'areasplinerange',
			legendIndex:3,
			//pointPlacement: 'on',
			data:[]
			
		} as  Highcharts.SeriesAreasplinerangeOptions
		this.highchartsOptions.series.push(usualSerie);
		
		let activeSerie={
			data:[],
			id: this.selectedTimeChartType.activityColumnName,
			name: this.translate.translate(this.selectedTimeChartType.activityColumnName),
			color: activeColor,
			legendIndex:1,
			//pointPlacement: 'on'
		}as Highcharts.SeriesColumnOptions;
		this.highchartsOptions.series.push(activeSerie);
		
		let activeLowSerie={
			data:[],
			type: 'scatter',
			id: 'aerial.user.inusual',
			name: this.translate.translate('aerial.user.inusual'),
			color: unusualColor,
			legendIndex:2,
			marker:{
				symbol: 'circle'
			}
			
			//pointPlacement: 'on'
		}as Highcharts.SeriesScatterOptions;
		this.highchartsOptions.series.push(activeLowSerie);
		
		let yAxisAsDatetime=this.selectedTimeChartType.activityColumnName=='aerial.user.activeHours'
		
		if (yAxisAsDatetime){
			let yAxis=this.highchartsOptions.yAxis as Highcharts.YAxisOptions;
			yAxis.type='datetime'
			yAxis.dateTimeLabelFormats={
				second: '%H:%M',
				day: '%H:%M'
			}

			activeSerie.tooltip= {
				pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:%H:%M}</b><br/>'
			}

			usualSerie.tooltip= {
				pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.low:%H:%M} - {point.high:%H:%M}</b><br/>'
			}
		}


		if (userActivityInfo){
			let usualDataAvailable=userActivityInfo.detailList.some(detail=>detail.usualMaxActiveMinutes!=null && detail.usualMaxActiveMinutes!=detail.usualMinActiveMinutes);
			userActivityInfo.detailList.forEach((detail,index)=>{
				let xValue=dayjs(this.dateToQuery).add(detail.label,this.selectedTimeChartType.unitLabelServerDetailLabel);
				let categoryDesc:string;
				if (this.selectedTimeChartType==TimeActivityChartTypes.Day){
					categoryDesc=xValue.format("HH:mm");
				}else{
					if (index==0 || xValue.date()==1){
						categoryDesc=xValue.format("DD MMM");
					}else{
						categoryDesc=xValue.format("DD");
					}
				}
				(this.highchartsOptions.xAxis as Highcharts.XAxisOptions).categories.push(categoryDesc);
				if (usualDataAvailable){
					
					usualSerie.data.push({
						//x:xValue,
						low:detail.usualMinActiveMinutes*(yAxisAsDatetime?60000:1),
						high:detail.usualMaxActiveMinutes*(yAxisAsDatetime?60000:1)
					});
				}
				let dataPoint={
					y:detail.activeMinutes*(yAxisAsDatetime?60000:1),
					//x: xValue

				} as Highcharts.PointOptionsObject
				
				
				if (usualDataAvailable && detail.usualMinActiveMinutes > detail.activeMinutes){
					dataPoint.color=unusualColor;
				}else{
				}
				activeSerie.data.push(dataPoint);
			});
		}

		
		this.highchartsObject=Highcharts.chart(this.chartElement, this.highchartsOptions);
		
		this.userActivityInfo=userActivityInfo;
		
		
	}

	changeTimeChartType(timeChartType:TimeActivityChartType){
		if (this.selectedTimeChartType!=timeChartType){
			this.selectedTimeChartType = timeChartType;
			this.queryChart();
		}
	}

	increaseDate() {
		this.dateToQuery=dayjs(this.dateToQuery).add(1,this.selectedTimeChartType.unitLabelToIncreaseOrDecrease).toDate();
		this.queryChart()
	}

	decreaseDate() {
		this.dateToQuery=dayjs(this.dateToQuery).subtract(1,this.selectedTimeChartType.unitLabelToIncreaseOrDecrease).toDate();
		this.queryChart()
	}

	isNumber(val: any){
		return typeof val == 'number';
	}

	isActivityLowAlert() {
		return (
		  this.userActivityInfo != null &&
		  this.isNumber(this.userActivityInfo.activeMinutes) &&
		  this.isNumber(this.userActivityInfo.usualMinActiveMinutes) &&
		  this.userActivityInfo.usualMinActiveMinutes >= this.userActivityInfo.activeMinutes
		);
	}
	  
}
