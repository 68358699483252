import {NgModule} from '@angular/core';

import {SharedModule} from '@app/shared/shared.module'
import {AerialCommonModule} from "@app/aerial/core/common/common.module";
import { AerialChangePasswordRoutingModule } from './changepassword-routing.module';
import { AerialChangePasswordComponent } from './changepassword.component';


@NgModule({
  imports: [
    SharedModule,
    AerialCommonModule,
    AerialChangePasswordRoutingModule
  ],
  declarations: [
    AerialChangePasswordComponent
  ],
  providers: [],
})
export class AerialChangePasswordModule {
	constructor() {
	}
}
