import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { AerialCommonModule } from '@app/aerial/core/common/common.module';
import { HttpClient,HttpParams } from "@angular/common/http";
import { UserCompleteInfo, UserNotificationInfo} from '@app/aerial/model/user.model';



@Component({
  selector: 'aerial-user-notifications',
  templateUrl: './usernotifications.component.html',
})

export class AerialUserNotificationsComponent implements OnInit {

	@Input() public userComplete: UserCompleteInfo;
	
	constructor(private el: ElementRef, private http:HttpClient) {}
	
	editable: boolean = false;
	
	timeList: number[] = [];
	hourList: object[] = [];
	displayAlerts: boolean = false; //AER-44
	
	ngOnInit() {
		this.initTimeList();
		this.initHourList();
		this.checkDisplayAlerts(); //AER-44
	}
	
	initHourList(){
		for(let i=0;i<=12;i++){
			if(i>0) this.hourList.push({value: i, name: ((i<10?"0":"")+ i+":00")});
			if(i<12) this.hourList.push({value: (i+0.5), name:((i<10?"0":"")+ i+":30")});
		}
	}
	
	initTimeList(){
		let year = 1970;
		let month = 0;
		let day = 1;
		
		for (let i = 0; i <= 23; i++) {
		  this.timeList.push(new Date(year, month, day, i, 0).getTime());
		  this.timeList.push(new Date(year, month, day, i, 30).getTime());
		}
	}
	
	public changeEditable(){
		this.editable = !this.editable;
	}
	
	public save(){
		this.http.post("/api/rest/user/save", this.userComplete).subscribe(
			(data: UserCompleteInfo) => {
				this.userComplete=data;
				this.editable=false;
			},
			(error) => {
				console.error('Error:', error);
			}
		);
	}
	
	public cancel(){
		this.http.get("/api/rest/user/"+this.userComplete.user.id).subscribe(
			(data: UserCompleteInfo) => {
				console.log(data);
				this.userComplete=data;
				this.editable=false;
			},
			(error) => {
				console.error('Error:', error);
			}
		);
	}
	
	//AER-44
	checkDisplayAlerts(){
		this.http.get("/api/rest/user/displayThirdPartAlerts").subscribe(
			(data: boolean) => {
				this.displayAlerts=data;
				if(!this.userComplete.alertConfigList){
					this.userComplete.alertConfigList = [];
				}
			},
			(error) => {
				console.error('Error:', error);
			}
		);		
	}
	//AER-44	
}
