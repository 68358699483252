<div id="content" *ngIf="alertMode=='list'">
	 <sa-widgets-grid>
		<div class="row">
			<div class="col-md-12 aerial-table" style="height:auto;border:none;">
				<span class="material-icons-outlined aerial-area-icon">warning_amber</span> <span class="aerial-area-title">{{'aerial.alerts' | translate}}</span>
				<sa-datatable id="userAlertTable"
					[options]="options"
            		filter="false" paginationLength="true"
            		tableClass="display projects-table table table-striped table-bordered table-hover table-alerts"
            		width="100%">
            		<thead>
						<tr>
	                  		<th style="vertical-align:middle"><span class="material-icons-outlined aerial-area-icon-14">flag</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.status' | translate }}</span></th>
	                  		<th style="vertical-align:middle"><span class="material-icons-outlined aerial-area-icon-14">calendar_today</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.creation.dateTime' | translate }}</span></th>
	                  		<th style="vertical-align:middle"><span class="material-icons-outlined aerial-area-icon-14">event_available</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.closed.dateTime' | translate }}</span></th>
	                  		<th style="vertical-align:middle"><span class="material-icons-outlined aerial-area-icon-14">category</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.type' | translate }}</span></th>
	                  		<th style="vertical-align:middle"><span class="material-icons-outlined aerial-area-icon-14">warning_amber</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.alert' | translate }}</span></th>
	                  		<th style="vertical-align:middle"><span class="material-icons-outlined aerial-area-icon-14">assignment_ind</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.agent' | translate }}</span></th>
                		</tr>
            		</thead>
				</sa-datatable>
			</div>
		</div>
	</sa-widgets-grid>
</div>
<div *ngIf="alertMode=='details'">
	<div class="col-md-12 aerial-alert-edit-panel" style="height:auto;">
		<div style="border-bottom: 1px solid #0066ff;">
			<span class="material-icons-outlined aerial-area-icon">warning_amber</span> <span class="aerial-area-title">{{'aerial.user.alerts.alert' | translate}}</span>
			<!--<div class="material-icons aerial-alert-button-cancel" style="float:right;margin-left:10px;" (click)="cancelAlert()">close</div><div class="material-icons aerial-alert-button-save" style="float:right;" (click)="saveAlert()">check</div>-->
			<button class="aerial-action-button pull-right"(click)="cancelAlert()"><i class="material-icons aerial-color-red aerial-action-button-icon">close</i></button>
			<button class="aerial-action-button pull-right" (click)="saveAlert()"><i class="material-icons aerial-color-blue aerial-action-button-icon">done</i></button>
		</div>
		<div class="row">
			<div class="col-md-3">
				<div class="aerial-field" style="padding-top:20px;">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-14">flag</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.status' | translate}}</span>
					</div>
					<div class="aerial-area-text-14">
						<select [(ngModel)]="alertInfo.statusCode" >
							<option value="OPEN" class="aerial-alert-status aerial-alert-status-open">{{'alert.status.open' | translate}}</option>
							<option value="PROGRESS" class="aerial-alert-status aerial-alert-status-progress">{{'alert.status.progress' | translate}}</option>
							<option value="CLOSED" class="aerial-alert-status aerial-alert-status-closed">{{'alert.status.closed' | translate}}</option>
						</select>
					</div>
				</div>
				<div class="aerial-field" style="padding-top:10px;">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-14">calendar_today</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.creation.dateTime' | translate}}</span>
					</div>
					<div class="aerial-area-text-14">
						<span>{{alertInfoView.creationDate}} {{alertInfoView.creationTime}}</span>
					</div>
				</div>
				<div class="aerial-field" style="padding-top:10px;">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-14">event_available</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.closed.dateTime' | translate}}</span>
					</div>
					<div class="aerial-area-text-14">
						<span>{{alertInfoView.closedDate}} {{alertInfoView.closedTime}}</span>
					</div>
				</div>
				<div class="aerial-field" style="padding-top:10px;">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-14">category</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.type' | translate}}</span>
					</div>
					<div class="aerial-area-text-14">
						<span>{{alertInfoView.type}}</span>
					</div>
				</div>
				<div class="aerial-field" style="padding-top:10px;">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-14">assignment_ind</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.agent' | translate}}</span>
					</div>
					<div class="aerial-area-text-14">
						<!--<span>{{alertInfoView.agentName}}</span>-->
						<select [(ngModel)]="alertInfo.agentId">
							<option [value]="agent.id" *ngFor="let agent of agentViewInfoList">{{agent.fullName}}</option>
						</select>
					</div>
				</div>
			</div>
		
			<div class="col-md-9">
				<div class="aerial-field" style="padding-top:20px;">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-14">warning_amber</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.alert' | translate}}</span>
					</div>
					<div class="aerial-area-text-14">
						<!--<input style="width:100%" type="text" [(ngModel)]="alertInfo.title" maxlength="1000">-->
						<span style="width:100%">{{alertInfo.title}}</span>
					</div>
				</div>
				<div class="aerial-field" style="padding-top:10px;">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-14">description</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.description' | translate}}</span>
					</div>
					<div class="aerial-area-text-14">
						<textarea style="width:100%" rows="6" class="aerial-alert-textarea-field" [(ngModel)]="alertInfo.description" maxlength="4000" placeholder="{{'aerial.user.alerts.description' | translate}}"></textarea>
					</div>
				</div>
				<div class="aerial-field" style="padding-top:10px;">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-14">rate_review</span><span class="aerial-area-icon-text-14">{{'aerial.user.alerts.resolution' | translate}}</span>
					</div>
					<div class="aerial-area-text-14">
						<textarea style="width:100%" rows="6" class="aerial-alert-textarea-field" [(ngModel)]="alertInfo.resolution" maxlength="4000" placeholder="{{'aerial.user.alerts.resolution' | translate}}"></textarea>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>