import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2} from '@angular/core';
import { HttpClient,HttpParams } from "@angular/common/http";
import { LayoutService } from '@app/core/services/layout.service';
import { TableColumn } from '@swimlane/ngx-datatable';
import { AerialTranslationService } from '@app/aerial/core/translation/translation.service';
import {AerialAlertSummaryInfo, AerialInstallationResultInfo} from './users.data';


@Component({
  selector: 'aerial-users',
  templateUrl: './users.component.html',
})
export class AerialUsersComponent implements OnInit {
	constructor(private layoutService: LayoutService, private http: HttpClient, private translate:AerialTranslationService,private renderer: Renderer2) {
		this.layoutService.onMenuOnTop();
		this.refreshAlertsSummary=this.refreshAlertsSummary.bind(this);
		this.setDeviceStatusAlertModeKeyPress=this.setDeviceStatusAlertModeKeyPress.bind(this);
		this.setActivityAlertModeKeyPress=this.setActivityAlertModeKeyPress.bind(this);
		this.setWakeUpAlertModeKeyPress=this.setWakeUpAlertModeKeyPress.bind(this);
		this.setOtherAlertModeKeyPress=this.setOtherAlertModeKeyPress.bind(this);
	}
	
	urlData:string = '/api/rest/user/list'; 
	
	public alertSummaryList: any;
	public deviceStatusFilterAlert: string = "";
	public activityFilterAlert: string = "";
	public wakeupFilterAlert: string = "";
	public otherAlertFilterAlert: string="";
	@ViewChild('deviceStatusInputText') deviceStatusInputText: ElementRef;
	@ViewChild('activityInputText') activityInputText: ElementRef;
	@ViewChild('wakeupInputText') wakeupInputText: ElementRef;
	@ViewChild('deviceStatusCheckButton') deviceStatusCheckButton: ElementRef;
	@ViewChild('activityCheckButton') activityCheckButton: ElementRef;
	@ViewChild('wakeupCheckButton') wakeupCheckButton: ElementRef;
	
	@ViewChild('otherAlertInputText') otherAlertInputText: ElementRef;
	@ViewChild('otherAlertCheckButton') otherAlertCheckButton: ElementRef;
	

	displayAlerts:boolean = false;
	aerialAutoSearchActive: boolean = false; //AER-50
	showFoundInstallationResponse: boolean = false; //AER-50
	foundInstallationResponseText: string = ""; //AER-50
	
	ngOnInit() {
		this.checkDisplayAlertColumns();
		this.checkAerialAutoSearch(); //AER-50
		this.refreshAlertsSummary();
		setInterval(this.refreshAlertsSummary, this.refreshTime);
	}
	
	public refreshTime:number = 60 * 1000; //1 min
	
	public options = {
						ajax: this.urlData, //'/api/rest/user/list',
						columns: [
                    		{data: 'user.fullName', render: this.renderFullName},
                    		{data: 'fullAddress'},
                    		{data: 'event.activity', render: this.renderInactivity},
                    		{data: 'event.sleep', render: this.renderWakeUp},
                    		{data: 'event.inactivityTime', render: this.renderInactivityTime},
                    		{data: 'event.sleepDisruptions', render: this.renderDreamInterrumtion},
                    		{data: 'event.alerts', render: this.renderAlerts},
                    		{data: 'event.installation', render: this.renderState},
                  		]
                  		//"order": [[1, 'asc']]

              		}
              		
	public renderFullName(data, type, row, meta): string{
		
		if(type=='sort'){
			return data;
		}else{
			
			if(row.vacation && row.vacation.onVacation){
				return '<a href="#/portal/users/details?id='+ row.user.id +'"><span class="material-icons-outlined aerial-area-icon-16 aerial-table-user-onvacation">beach_access</span><span class="aerial-table-user-onvacation">'+ data +'</span></a>';
			}else{
				return '<a href="#/portal/users/details?id='+ row.user.id +'"><span>'+ data +'</span></a>';
			}
		}
	}
    
    public renderWakeUp(data, type, row, meta): string{
		if(type=='sort'){
			return data==true?'1':'0';
		}else{
			if(data==true){
				return '<a href="#/portal/users/details?id='+ row.user.id +'&submenu=sleep"><span class="material-icons-outlined aerial-alert-warning">airline_seat_individual_suite</span><span style="display:none;">alert</span></a>';
			}else{
				return '<a href="#/portal/users/details?id='+ row.user.id +'&submenu=sleep"><span class="material-icons-outlined aerial-alert-normal">airline_seat_individual_suite</span></a>';
			}
		}
	}
	
	public renderInactivityTime(data, type, row, meta): string{
		return data;
	}
    
    public renderDreamInterrumtion(data, type, row, meta): string{
		return data;
	}
	public renderState(data, type, row, meta): string{
		if(type=='sort'){
			return data==true?'1':'0';
		}else{
			if(data==true){
				return '<a href="#/portal/users/details?id='+ row.user.id +'&submenu=devices"><span class="material-icons-outlined aerial-alert-warning">wifi_tethering</span><span style="display:none;">alert</span></a>';
			}else{
				return '<a href="#/portal/users/details?id='+ row.user.id +'&submenu=devices"><span class="material-icons-outlined aerial-alert-normal">wifi_tethering</span></a>';
			}
		}
	}
    public renderInactivity(data, type, row, meta): string{
		if(type=='sort'){
			return data==true?'1':'0';
		}else{
			if(data==true){
				return '<a href="#/portal/users/details?id='+ row.user.id +'&submenu=activity"><span class="material-icons-outlined aerial-alert-warning">directions_walk</span><span style="display:none;">alert</span></a>';
			}else{
				return '<a href="#/portal/users/details?id='+ row.user.id +'&submenu=activity"><span class="material-icons-outlined aerial-alert-normal">directions_walk</span></a>';
			}
		}
	}
	
	//AER-29
	public refreshAlertsSummary(){
		 this.http.get('/api/rest/alert/summary').subscribe((result:AerialAlertSummaryInfo)=>{
			this.alertSummaryList = result;	
		})
	}
	
	public setDeviceStatusAlertMode(){
		
		let isChecked = this.deviceStatusCheckButton.nativeElement.checked;
		
		if(isChecked){
			this.deviceStatusFilterAlert="alert";
		}else{
			this.deviceStatusFilterAlert="";
		}
		setTimeout(this.setDeviceStatusAlertModeKeyPress,10);
	}
	public setDeviceStatusAlertModeKeyPress(){
		this.deviceStatusInputText.nativeElement.dispatchEvent(new KeyboardEvent('keyup', {key:'Enter',bubbles:true,cancelable:false}));
	}
	
	public setActivityAlertMode(){
		
		let isChecked = this.activityCheckButton.nativeElement.checked;
		
		if(isChecked){
			this.activityFilterAlert="alert";
		}else{
			this.activityFilterAlert="";
		}
		setTimeout(this.setActivityAlertModeKeyPress,10);
	}
	public setActivityAlertModeKeyPress(){
		this.activityInputText.nativeElement.dispatchEvent(new KeyboardEvent('keyup', {key:'Enter',bubbles:true,cancelable:false}));
	}
	
	public setWakeUpAlertMode(){
		
		let isChecked = this.wakeupCheckButton.nativeElement.checked;
		
		if(isChecked){
			this.wakeupFilterAlert="alert";
		}else{
			this.wakeupFilterAlert="";
		}
		setTimeout(this.setWakeUpAlertModeKeyPress,10);
	}
	public setWakeUpAlertModeKeyPress(){
		this.wakeupInputText.nativeElement.dispatchEvent(new KeyboardEvent('keyup', {key:'Enter',bubbles:true,cancelable:false}));
	}

	public filterOnlyAlerts(alertSummaryCode:string){
		if(alertSummaryCode!=null && alertSummaryCode!=""){
			if(alertSummaryCode=="CONNECT_ERROR"){
				if(!this.deviceStatusCheckButton.nativeElement.checked){
					this.renderer.selectRootElement(this.deviceStatusCheckButton.nativeElement).click();
				}
				if(this.activityCheckButton.nativeElement.checked){
					this.renderer.selectRootElement(this.activityCheckButton.nativeElement).click();
				}
				if(this.wakeupCheckButton.nativeElement.checked){
					this.renderer.selectRootElement(this.wakeupCheckButton.nativeElement).click();
				}
			}else if(alertSummaryCode=="NOACTIVITY"){
				if(!this.activityCheckButton.nativeElement.checked){
					this.renderer.selectRootElement(this.activityCheckButton.nativeElement).click();
				}
				if(this.deviceStatusCheckButton.nativeElement.checked){
					this.renderer.selectRootElement(this.deviceStatusCheckButton.nativeElement).click();
				}
				if(this.wakeupCheckButton.nativeElement.checked){
					this.renderer.selectRootElement(this.wakeupCheckButton.nativeElement).click();
				}
			}else if(alertSummaryCode=="NOWAKEUP"){
				if(!this.wakeupCheckButton.nativeElement.checked){
					this.renderer.selectRootElement(this.wakeupCheckButton.nativeElement).click();
				}
				if(this.activityCheckButton.nativeElement.checked){
					this.renderer.selectRootElement(this.activityCheckButton.nativeElement).click();
				}
				if(this.deviceStatusCheckButton.nativeElement.checked){
					this.renderer.selectRootElement(this.deviceStatusCheckButton.nativeElement).click();
				}
			}
		}
	}
	//AER-29 end

	public renderAlerts(data, type, row, meta): string{
		if(type=='sort'){
			return data==true?'1':'0';
		}else{
			if(data==true){
				return '<a href="#/portal/users/details?id='+ row.user.id +'&submenu=alerts"><span class="material-icons-outlined aerial-alert-warning">warning_amber</span><span style="display:none;">alert</span></a>';
			}else{
				return '<a href="#/portal/users/details?id='+ row.user.id +'&submenu=alerts"><span class="material-icons-outlined aerial-alert-normal">warning_amber</span></a>';
			}
		}
	}
	
	checkDisplayAlertColumns(){
		this.http.get("/api/rest/user/displayThirdPartAlerts").subscribe(
			(data: boolean) => {
				this.displayAlerts=data;
			},
			(error) => {
				console.error('Error:', error);
			}
		);		
	}	
	
	
	public setOtherAlertMode(){
		
		let isChecked = this.otherAlertCheckButton.nativeElement.checked;
		
		if(isChecked){
			this.otherAlertFilterAlert="alert";
		}else{
			this.otherAlertFilterAlert="";
		}
		setTimeout(this.setOtherAlertModeKeyPress,10);
	}
	public setOtherAlertModeKeyPress(){
		this.otherAlertInputText.nativeElement.dispatchEvent(new KeyboardEvent('keyup', {key:'Enter',bubbles:true,cancelable:false}));
	}
	
	//AER-50
	checkAerialAutoSearch(){
		this.http.get("/api/rest/user/checkAerialAutoSearch").subscribe(
			(data: boolean) => {
				this.aerialAutoSearchActive=data;
			},
			(error) => {
				console.error('Error:', error);
			}
		);
	}
	
	public searchAerialInstallations(){
		this.showFoundInstallationResponse = true;
		this.foundInstallationResponseText=this.translate.translate('aerial.installations.searching');
		
		this.http.get("/api/rest/user/searchInstallations").subscribe(
			(data: AerialInstallationResultInfo) => {
				console.log("Number of new installations:" + data);	//AER-50
				let result: string = "";
				if(data!=null){
					if(data.countNewUsers>0){
						let installationText = this.translate.translate('aerial.installations.user');
						installationText = installationText.replace('<$USERS$>', ('' + data.countNewUsers));
						result += installationText + ".";
					}
					if(data.countNewDevices>0){
						let installationText = this.translate.translate('aerial.installations.devices');
						installationText = installationText.replace('<$DEVICES$>', ('' + data.countNewDevices));
						if(result!='') result += ' ';
						result += installationText + ".";
					}
				}
				
				if(result==''){
					result = this.translate.translate('aerial.installations.notFound');
				}
				
				this.showFoundInstallationResponse = true;
				this.foundInstallationResponseText = result;
				
				setTimeout(() => {
			      this.showFoundInstallationResponse = false;
			    },7000);
			},
			(error) => {
				console.error('Error:', error);
			}
		);

	}
	//AER-50
}
