import {NgModule} from '@angular/core';

import {SharedModule} from '@app/shared/shared.module'

import {LoginRoutingModule} from './login-routing.module';
import {AerialLoginComponent} from './login.component';
import {AerialCommonModule} from "@app/aerial/core/common/common.module";
import {AerialChangePasswordModule} from './changepassword/changepassword.module';


@NgModule({
  imports: [
    SharedModule,
    LoginRoutingModule,
    AerialCommonModule,
    AerialChangePasswordModule
    
  ],
  declarations: [
    AerialLoginComponent
  ],
  providers: [],
})
export class AerialLoginModule {

}
