<div class="col-md-12 aerial-sleep-box-container">
	<div>
		<span class="material-icons aerial-sleep-box-icon" style="padding-bottom:2px;">bedtime</span>
		<span style="padding-left: 15px">Sueño</span>
		<div style="float:right;">
			<select class="aerial-sleep-box-select">
				<option>Diario</option>
				<option>Semanal</option>
				<option>Mensual</option>
			</select>
		</div>
	</div>
	<div style="border-bottom:solid 1px #0066ff;min-height:1px;max-height:1px;margin-top:10px;"></div>
	<div style="min-height:100px;max-height:200px;"></div>
	
	<div style="margin-top:10px;margin-bottom:10px;">Promedio por día</div>
	
	<div style="margin-top:10px;font-size:14px;">
		<div>
			<span style="color:#031a34;font-weight: bold;">Tiempo durmiendo</span>
			<span style="float:right;text-align:right;">10h 51m</span>
		</div>
		<div style="margin-top:10px;color:#999999;">
			<span>Rango habitual</span>
			<span style="float:right;text-align:right;">9h 47m - 11h 17m</span>
		</div>
		<div style="border-bottom:solid 1px #999999;min-height:1px;max-height:1px;margin-top:10px;"></div>
	</div>
	
	<div style="margin-top:10px;font-size:14px;">
		<div>
			<span style="color:#031a34;font-weight: bold;">Interrupciones del sueño</span>
			<span style="float:right;text-align:right;">1</span>
		</div>
		<div style="margin-top:10px;color:#999999;">
			<span>Rango habitual</span>
			<span style="float:right;text-align:right;">1 - 1</span>
		</div>
		<div style="border-bottom:solid 1px #999999;min-height:1px;max-height:1px;margin-top:10px;"></div>
	</div>
	
	<div style="margin-top:10px;font-size:14px;">
		<div>
			<span style="color:#031a34;font-weight: bold;">Hora de ir a dormir</span>
			<span style="float:right;text-align:right;">22:22</span>
		</div>
		<div style="margin-top:10px;color:#999999;">
			<span>Rango habitual</span>
			<span style="float:right;text-align:right;">23:15 - 23:53</span>
		</div>
		<div style="border-bottom:solid 1px #999999;min-height:1px;max-height:1px;margin-top:10px;"></div>
	</div>
	
	<div style="margin-top:10px;font-size:14px;">
		<div>
			<span style="color:#031a34;font-weight: bold;">Hora de levantarse de la cama</span>
			<span style="float:right;text-align:right;">8:15</span>
		</div>
		<div style="margin-top:10px;color:#999999;">
			<span>Rango habitual</span>
			<span style="float:right;text-align:right;">9:55 - 10:39</span>
		</div>
		<div style="border-bottom:solid 1px #999999;min-height:1px;max-height:1px;margin-top:10px;"></div>
	</div>
	
</div>