import {NgModule} from '@angular/core';

import {SharedModule} from '@app/shared/shared.module'
import { LayoutService } from '@app/core/services/layout.service';
import { AerialAdministratorsRoutingModule } from './administrators-routing.module';
import { AerialAdministratorsListComponent } from './list/administrator.list.component';
import {AerialCommonModule} from "@app/aerial/core/common/common.module";
import { SmartadminDatatableModule } from '@app/shared/ui/datatable/smartadmin-datatable.module';
import { AerialAdministratorDetailsModule } from "./details/administrator.details.module";



@NgModule({
  imports: [
    SharedModule,
    AerialAdministratorsRoutingModule,
    AerialCommonModule,
    SmartadminDatatableModule,
    AerialAdministratorDetailsModule
  ],
  declarations: [
    AerialAdministratorsListComponent
  ],
  providers: [],
})
export class AerialAdministratorsModule {
	constructor(private layoutService: LayoutService) {
		this.layoutService.onMenuOnTop();
	}
}
