import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { AerialCommonModule } from '@app/aerial/core/common/common.module';


@Component({
  selector: 'aerial-user-meta',
  templateUrl: './usermeta.component.html',
})

export class AerialUserMetaComponent implements OnInit {

	@Input() public budgetClass: string;
	@Input() public userComplete: any;
	
	constructor(private el: ElementRef) {}
	
	ngOnInit() {
			}
}
