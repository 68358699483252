<div id="content">
	<div class="col-md-12 aerial-user-header-div-container">
		<div class="aerial-area-title" style="border-bottom: 1px solid #0066FF;padding-right: 0px;">
			<span class="material-icons-outlined aerial-area-icon" style="padding-left: 0px">passkey</span><span *ngIf="agentDetailInfo" style="padding-left: 15px">{{agentDetailInfo.fullName}}</span>
			<a href="#/portal/administrators" class="aerial-back-button" style="float:right;"><div class="material-icons" style="padding-top:2px;">chevron_left</div><div style="float:right;padding-top:5px;">{{'aerial.user.details.backToUserList' | translate}}</div></a>
			<div class="pull-right" style="margin-right: 10px">
				<button class="aerial-action-button pull-right" (click)="changeEditable()" *ngIf="!editable"><i class="material-icons-outlined aerial-color-grey aerial-action-button-icon">mode_edit</i></button>
				<button class="aerial-action-button pull-right"(click)="cancel()" *ngIf="editable"><i class="material-icons-outlined aerial-color-red aerial-action-button-icon">close</i></button>
				<button class="aerial-action-button pull-right" (click)="save()" *ngIf="editable"><i class="material-icons-outlined aerial-color-blue aerial-action-button-icon">done</i></button>
			</div>
		</div>
	</div>
	<div class="col-md-12 aerial-user-menu" style="padding-top:15px; padding-bottom: 15px">
		<span class="material-icons-outlined aerial-area-icon-16" style="padding-left: 20px">tab_move</span><span class="aerial-area-icon-text-14" style="padding-left: 15px">{{'aerial.agent.lastValidLogin' | translate}}:</span><span style="padding-left: 10px; font-size: 14px;" *ngIf="agentDetailInfo && agentDetailInfo.lastValidLogin" >{{agentDetailInfo.lastValidLogin | datetime}}</span>
		<span class="material-icons-outlined aerial-area-icon-16" style="padding-left: 20px">feedback</span><span class="aerial-area-icon-text-14" style="padding-left: 15px">{{'aerial.agent.invalidRetries' | translate}}:</span><span style="padding-left: 10px; font-size: 14px;">{{agentDetailInfo.loginInvalidRetries | number}}</span>
		<span class="material-icons-outlined aerial-area-icon-16" style="padding-left: 20px">bring_your_own_ip</span><span class="aerial-area-icon-text-14" style="padding-left: 15px">{{'aerial.agent.lastLoginIpAddress' | translate}}:</span><span style="padding-left: 10px; font-size: 14px;">{{agentDetailInfo.lastLoginIpAddress}}</span>
		<div class="aerial-label-warning pull-right" style="display: inline-flex;align-items: center;" *ngIf="genericError"><span><i class="material-icons-outlined">error</i></span><span style="margin-left: 5px">{{'aerial.agent.save.genericError' | translate}}</span></div>
		<div class="pull-right" style="display: inline-flex;align-items: center;" *ngIf="notificationMsg">
			<span *ngIf="!notificationWarning"><i class="material-icons-outlined aerial-color-blue">done</i></span>
			<span *ngIf="!notificationWarning" class="aerial-color-blue" style="margin-left: 5px; margin-top: -10px;">{{notificationMsg | translate}}</span>
			<span *ngIf="notificationWarning" class="aerial-label-warning"><i class="material-icons-outlined">error</i></span>
			<span *ngIf="notificationWarning" class="aerial-label-warning" style="margin-left: 5px; margin-top: -10px;">{{notificationMsg | translate}}</span>
		</div>
	</div>
	<div class="col-md-12 aerial-user-body-div-container">
		<!-- div class="col-md-12">
			<button class="aerial-action-button pull-right" (click)="changeEditable()" *ngIf="!editable"><i class="material-icons-outlined aerial-color-grey aerial-action-button-icon">mode_edit</i></button>
			<button class="aerial-action-button pull-right"(click)="cancel()" *ngIf="editable"><i class="material-icons-outlined aerial-color-red aerial-action-button-icon">close</i></button>
			<button class="aerial-action-button pull-right" (click)="save()" *ngIf="editable"><i class="material-icons-outlined aerial-color-blue aerial-action-button-icon">done</i></button>
		</div -->
		<div class="col-md-9">
			<div class="col-md-6">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-16">badge</span><span class="aerial-area-icon-text-14">{{'aerial.agent.account' | translate}}</span>
						<div class="aerial-label-warning pull-right" style="display: inline-flex;align-items: center;" *ngIf="accountAlreadyUsed"><span><i class="material-icons-outlined">error</i></span><span style="margin-left: 5px">{{'aerial.agent.account.alreadyInUse' | translate}}</span></div>
					</div>
					<div class="aerial-field">
						<input type="text" [(ngModel)]="agentDetailInfo.account" [disabled]="!editable">
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-16">mail</span><span class="aerial-area-icon-text-14">{{'aerial.email' | translate}}</span>
					</div>
					<div class="aerial-field">
						<input type="text" [(ngModel)]="agentDetailInfo.email" [disabled]="!editable">
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-16">person</span><span class="aerial-area-icon-text-14">{{'aerial.firstName' | translate}}</span>
					</div>
					<div class="aerial-field">
						<input type="text" [(ngModel)]="agentDetailInfo.firstName" [disabled]="!editable">
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-16">person</span><span class="aerial-area-icon-text-14">{{'aerial.lastName' | translate}}</span>
					</div>
					<div class="aerial-field">
						<input type="text" [(ngModel)]="agentDetailInfo.lastName" [disabled]="!editable">
					</div>
				</div>
			</div>
			<!-- div class="col-md-4">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-16">check_circle</span><span class="aerial-area-icon-text-14">{{'aerial.login.allowed' | translate}}</span>
					</div>
					<div class="aerial-field">
						<div class="aerial-radio">
							<input name="logingActive" ng-control="alone" type="radio" [value]="true"  [(ngModel)]="agentDetailInfo.loginActive" [disabled]="!editable">							
							<span class="">{{'aerial.yes' | translate}}</span>
							<input name="logingActive" ng-control="alone" type="radio" [value]="false" [(ngModel)]="agentDetailInfo.loginActive" [disabled]="!editable">
							<span class="">{{'aerial.no' | translate}}</span>
						</div>
					</div>
				</div>
			</div -->
		</div>
		<div class="col-md-3" *ngIf="agentDetailInfo.id">
			<div class="col-md-12" style="margin-top: 20px" *aerialProfile="'resetPassword'" >
				<aerial-actionbudget iconClass="aerial-alert-icon-black material-icons-outlined" iconName="password" (actionClick)="resetPassword()" actionText="{{'aerial.agent.resetPassword'|translate}}"></aerial-actionbudget>
			</div>
			<div class="col-md-12" style="margin-top: 20px" *ngIf="agentDetailInfo.loginActive">
				<aerial-actionbudget *aerialProfile="'disableAgentLogin'" iconClass="aerial-alert-icon-disable material-icons-outlined" iconName="account_circle_off" (actionClick)="disableLogin()" actionText="{{'aerial.agent.disableAgentLogin'|translate}}"></aerial-actionbudget>
			</div>
			<div class="col-md-12" style="margin-top: 20px" *ngIf="!agentDetailInfo.loginActive">
				<aerial-actionbudget *aerialProfile="'enableAgentLogin'" iconClass="aerial-alert-icon-normal material-icons-outlined" iconName="account_circle" (actionClick)="enableLogin()" actionText="{{'aerial.agent.enableAgentLogin'|translate}}"></aerial-actionbudget>
			</div>
			<div class="col-md-12" style="margin-top: 20px" *ngIf="agentDetailInfo.loginActive">
				<aerial-actionbudget *aerialProfile="'unbBlock'" iconClass="aerial-alert-icon-normal material-icons-outlined" iconName="lock_open" (actionClick)="unlockAgent()" actionText="{{'aerial.agent.unbBlock'|translate}}"></aerial-actionbudget>
			</div>
			<div class="col-md-12" style="margin-top: 20px">
				<aerial-actionbudget *aerialProfile="'deleteAgent'" iconClass="aerial-alert-icon-warning material-icons-outlined" iconName="delete" (actionClick)="deleteAgent()" actionText="{{'aerial.agent.deleteAgent'|translate}}"></aerial-actionbudget>
			</div>
		</div>
	</div>
</div>