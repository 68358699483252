import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AerialLoginComponent} from "./login.component";

const routes: Routes = [{
  path: '',
  component: AerialLoginComponent,
  data: {pageTitle: 'Login'}
},
{
    path: 'changePassword',
    loadChildren:'./changepassword/changepassword.module#AerialChangePasswordModule',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LoginRoutingModule { }
