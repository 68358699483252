import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { LayoutService } from '@app/core/services/layout.service';
import { TableColumn } from '@swimlane/ngx-datatable';


@Component({
  selector: 'aerial-administrator-list',
  templateUrl: './administrator.list.component.html',
})
export class AerialAdministratorsListComponent implements OnInit {
	constructor(private layoutService: LayoutService) {
		this.layoutService.onMenuOnTop();
	}
	
	urlData:string = '/api/rest/agent/search'; 
	
	ngOnInit() {
	  /*
	  $.extend(true, $.fn.dataTable.defaults, {
     	 language: {
      	 	 url: "/assets/aerial/i18n/Spanish.json"
      		}
    	});
    	*/
	}
	
	public options = {
						ajax: this.urlData,
						columns: [
                    		{data: 'firstName', render: this.renderGeneral},
                    		{data: 'lastName', render: this.renderGeneral},
                    		{data: 'account', render: this.renderGeneral},
                    		{data: 'email', render: this.renderGeneral},
                    		{data: 'loginActive', render: this.renderState},
                  		]
                  		//"order": [[1, 'asc']]

              		}
              	
      public renderGeneral(data, type, row, meta): string{
			return '<a href="#/portal/administrators/details?id='+ row.id +'"><span>'+ data +'</span></a>';
		}
		
	public renderState(data, type, row, meta): string{
			return '<a href="#/portal/administrators/details?id='+ row.id +'"><span>'+ (data?'Active':'Inactive') +'</span></a>'; //TODO
		}
}
