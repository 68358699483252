import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AerialUserDetailsComponent} from "./user.details.component";

const routes: Routes = [{
		path: '',
		component: AerialUserDetailsComponent,
		data: {pageTitle: 'User Details'}
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AerialUserDetailsRoutingModule { }
