import {ModuleWithProviders} from "@angular/core"
import {Routes, RouterModule} from '@angular/router';

import { MainLayoutComponent } from "../shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "../shared/layout/app-layouts/auth-layout.component";


export const routes: Routes = [
  {
    path: '', redirectTo: 'aerial', pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren:'./login/login.module#AerialLoginModule',
    component: AuthLayoutComponent

  },
  {
    path: 'portal',
    loadChildren:'./portal/portal.module#AerialPortalModule',
    component: MainLayoutComponent
  }
];

export const routing = RouterModule.forChild(routes);
