

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {I18nModule} from "./../../../shared/i18n/i18n.module";
import {BigBreadcrumbsComponent} from "./../../../shared/layout/navigation/big-breadcrumbs.component";
import {MinifyMenuComponent} from "./../../../shared/layout/navigation/minify-menu.component";
import {AerialNavigationComponent} from "./navigation.component";
import {SmartMenuDirective} from "./../../../shared/layout/navigation/smart-menu.directive";
import {UserModule} from "./../../../shared/user/user.module";
import {RouterModule} from "@angular/router";
import {ChatModule} from "./../../../shared/chat/chat.module";
import {AerialCommonModule} from "@app/aerial/core/common/common.module";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    UserModule,
    ChatModule,
    AerialCommonModule
  ],
  declarations: [
    BigBreadcrumbsComponent,
    MinifyMenuComponent,
    AerialNavigationComponent,
    SmartMenuDirective
  ],
  exports: [
    BigBreadcrumbsComponent,
    MinifyMenuComponent,
    AerialNavigationComponent,
    SmartMenuDirective,
  ]
})
export class AerialNavigationModule{}
