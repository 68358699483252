<!-- RIBBON -->
<div id="ribbon">
  <ng-template #tipContent><i class="text-warning fa fa-warning"></i> Warning! This will reset all your widget settings.</ng-template>

    <span class="ribbon-button-alignment">
      <span id="refresh" class="btn btn-ribbon" (click)="resetWidgets()"
            placement="bottom"
            [tooltip]="tipContent">
        <i class="fa fa-refresh"></i>
      </span>
    </span>
  <sa-route-breadcrumbs></sa-route-breadcrumbs>
</div>
