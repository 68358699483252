import { Component, Input, OnInit, ElementRef} from '@angular/core';
import { DeviceInfo } from '@app/aerial/model/user.model';
import { HttpClient,HttpParams } from "@angular/common/http";
import { ThirdPartLocationInfo } from '@app/aerial/model/user.model';


@Component({
  selector: 'aerial-devicebox',
  templateUrl: './devicebox.component.html',
})

export class AerialDeviceBoxComponent implements OnInit {

	@Input() public device: DeviceInfo;
	@Input() public thirdPartLocationList:ThirdPartLocationInfo[];
	/*
	@Input() public deviceName: string;
	@Input() public deviceType: string;
	@Input() public deviceTypeIconName: string;
	@Input() public connected: string;
	*/

	editable: boolean = false;
	deviceLocationList:string[] = [];
	sensibilityList:number[] = [0,1,2,3,4];
	modelList:any[]=[];
	displayStatusMessage: boolean = false;
	
	constructor(private el: ElementRef, private http:HttpClient) {
	}
	
	ngOnInit() {
		//console.log("OnInit: deviceName["+this.deviceName+"] connected["+this.connected+"] typeof(this.connected)["+typeof(this.connected)+"]");
		this.initDeviceLocationList();
		this.initModelList();
		this.refreshDevice();
	}
	
	public changeEditable(){
		this.editable = true;
	}
	
	public save(){
		this.http.post("/api/rest/user/"+this.device.userId+"/device/save", this.device).subscribe(
			(data: DeviceInfo) => {
				this.device=data;
				this.editable=false;
			},
			(error) => {
				console.error('Error:', error);
			}
		);	
	}
	
	public cancel(){
		this.http.get("/api/rest/user/"+this.device.userId+"/device/"+this.device.deviceId).subscribe(
			(data: DeviceInfo) => {
				this.device=data;
				this.editable=false;
			},
			(error) => {
				console.error('Error:', error);
			}
		);
	}
	
	public refreshDevice(){
		this.displayStatusMessage = false;
		
		this.http.get("/api/rest/user/"+this.device.userId+"/device/"+this.device.deviceId).subscribe(
			(data: DeviceInfo) => {
				this.device=data;
				
				if(this.device.connected && this.device.statusMessage!=null &&  !(this.device.statusMessage=='NORMAL' || this.device.statusMessage=='UNKNOWN')){
					this.displayStatusMessage = true;
				}
			},
			(error) => {
				console.error('Error:', error);
			}
		);
	}
	
	public deviceImage(){
		let result='../../assets/img/aerial/devices/default_50x50px.png';
		if(this.modelList!=null && this.modelList.length>0){
			for(let i=0;i<this.modelList.length;i++){
				if(this.modelList[i].id==this.device.modelId){
					result='../../assets/img/aerial/devices/' + this.modelList[i].img;
				}
			}
		}
		
		return result;
		
	}
	
	public displaySensitivityAndLocation(){
		let result=false;
		if(this.modelList!=null && this.modelList.length>0){
			for(let i=0;i<this.modelList.length;i++){
				if(this.modelList[i].id==this.device.modelId){
					result=this.modelList[i].displaySensitivity;
				}
			}
		}
		
		return result;
	}
	
	
	initDeviceLocationList(){
		this.deviceLocationList = ['bathroom', 'bedroom', 'kitchen', 'living_room', 'other'];		
	}
	
	initModelList(){
		this.http.get("/api/rest/model/list").subscribe(
			(data: any) => {
				this.modelList=data;
			},
			(error) => {
				console.error('Error:', error);
			}
		);
	}
	
	public renderStatusLastUpdate(): string{
		let result="";
		if(this.device!=null && this.device.statusLastUpdate!=null && this.device.statusLastUpdate>0){
			let statusLastUpdateDate = new Date();
			statusLastUpdateDate.setTime(this.device.statusLastUpdate);
			
			const dateFormat = new Intl.DateTimeFormat('es-ES', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			});
			result=dateFormat.format(statusLastUpdateDate);
		}
		
		return result;

	}
	
	public displayAdditionalData(): boolean{
		return (this.device!=null && this.device.additionalData!=null);
	}
	
	public displayBatteryData(): boolean{
		return (this.device!=null && this.device.additionalData!=null && this.device.additionalData.hasOwnProperty('batteryLastUpdated'));
	}
	
}
