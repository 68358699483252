	export function convertirFechaATimestamp(fechaString: string): number | null {
	  // Divide la cadena de fecha en día, mes y año
	  const partes = fechaString.split('/');
	  
	  // Verifica si hay suficientes partes y son números válidos
	  if (partes.length === 3 && partes.every(part => !isNaN(Number(part)))) {
	    const dia = Number(partes[0]);
	    const mes = Number(partes[1]) - 1; // Los meses en JavaScript van de 0 a 11
	    const año = Number(partes[2]);
	
	    // Crea un objeto Date con la fecha proporcionada
	    const fecha = new Date(año, mes, dia);
	
	    // Verifica si la fecha es válida
	    if (!isNaN(fecha.getTime())) {
	      // Devuelve el timestamp en milisegundos
	      return fecha.getTime();
	    }
	  }
	
	  // Si la conversión no fue exitosa, devuelve null
	  return null;
	}
	
	export function convertirTimestampAFecha(timestamp: number): string {
	  const fecha = new Date(timestamp);
	  
	  // Obtiene día, mes y año
	  const dia = fecha.getDate().toString().padStart(2, '0');
	  const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Se suma 1 porque los meses van de 0 a 11
	  const año = fecha.getFullYear();
	
	  // Concatena los componentes de la fecha en el formato deseado
	  return `${dia}/${mes}/${año}`;
	}