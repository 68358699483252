import { Component, OnInit, Input } from '@angular/core';
import { HttpClient,HttpParams } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { ChangePasswordInfo, ChangePasswordErrorInfo } from '@app/shared/login/login.model';

@Component({
  selector: 'aerial-login-changepassword',
  templateUrl: './changepassword.component.html',
})

export class AerialChangePasswordComponent implements OnInit {
	
	changePasswordInfo: ChangePasswordInfo;
	passwordErrorInfo: ChangePasswordErrorInfo;
	invalidMsg: string;
		
	constructor(private http:HttpClient, private route: ActivatedRoute, private router: Router) {
	}
	
	
	ngOnInit() {
		this.changePasswordInfo = {
			oldPassword: '',
			newPassword: '',
			repeatNewPassword: ''
		}
	}
	
	
	changePassword($event){
		this.passwordErrorInfo = null;
		this.invalidMsg = null;
		this.http.post("/api/rest/login/changePassword", this.changePasswordInfo).subscribe(
			(data: any) => {
				if(data.agent){
			        this.router.navigate(['/portal']); 
				}
			},
			(error) => {
				console.log(error)
				if(error.status==401){
					this.invalidMsg = 'aerial.login.invalidPwd';
				}else{
					this.passwordErrorInfo = error.error;	
				}
			}
		);
	}
}
