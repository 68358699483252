import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationInfo, LoginPasswordAuthorization } from '@app/shared/login/login.model';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  isLoggedIn: boolean = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  login( loginPasswordAuthorization:LoginPasswordAuthorization): Observable<AuthorizationInfo> {

    return this.http.post<AuthorizationInfo>("/api/rest/login", loginPasswordAuthorization);
  }

  logout(): Observable<void> {
	 this.isLoggedIn = false;
	 return this.http.get<void>("/api/rest/login/out");	//AER-23
  }

  constructor(private http: HttpClient){}
}
