import { Component, Input, Output, OnInit, ElementRef, EventEmitter } from '@angular/core';


@Component({
  selector: 'aerial-actionbudget',
  templateUrl: './action.component.html',
})

export class AerialActionBudgetComponent implements OnInit {

	@Input() public budgetClass: string;
	@Input() public iconName: string;
	@Input() public iconClass: string;
	@Input() public actionText: string;
	@Output() actionClick = new EventEmitter<void>();

	constructor(private el: ElementRef) {}
	
	ngOnInit() {
		this.iconName = this.iconName || ' ';
	}
	
	executeActionClick(){
		this.actionClick.emit();
	}
}
