

export type AerialUserActivityStepInfo = {
    label: number;
    activeMinutes: number;
    usualMinActiveMinutes: number;
    usualMaxActiveMinutes: number;
}

export type AerialUserActivityInfo ={
	detailList: AerialUserActivityStepInfo[];
  activeMinutes: number;
  usualMinActiveMinutes: number;
  usualMaxActiveMinutes: number;
}
import { DurationInputArg2 } from "moment";
import { ManipulateType } from "dayjs";

// Definir una interfaz para describir la estructura de los objetos
 export interface TimeActivityChartType {
    label: string;
    querySelector: string;
    activityColumnName: string;
    unitLabelServerDetailLabel: ManipulateType;
    unitLabelToIncreaseOrDecrease: ManipulateType;
  }
  
  // Definir un objeto constante que mapea las constantes a objetos con la interfaz como tipo
  export const TimeActivityChartTypes: Record<string, TimeActivityChartType> = {
    Day: { querySelector: 'daily', label: 'aerial.time.day',activityColumnName:'aerial.user.activeMinutes',unitLabelServerDetailLabel:'hour',unitLabelToIncreaseOrDecrease:'day'},
    Week: { querySelector: 'weekly', label: 'aerial.time.week',activityColumnName:'aerial.user.activeHours', unitLabelServerDetailLabel:'day',unitLabelToIncreaseOrDecrease:'week'},
    Month: { querySelector: 'monthly', label: 'aerial.time.month',activityColumnName:'aerial.user.activeHours',unitLabelServerDetailLabel:'day',unitLabelToIncreaseOrDecrease:'month'},
  };
  