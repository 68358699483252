import { Component, Input, OnInit, ElementRef, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { LayoutService } from '@app/core/services/layout.service';
import { AerialCommonModule } from '@app/aerial/core/common/common.module';
import { TableColumn } from '@swimlane/ngx-datatable';
import { HttpClient,HttpParams } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { AerialTranslationService } from '@app/aerial/core/translation/translation.service';
import {AerialAgentViewInfo,AerialAlertInfo,AerialAlertInfoView} from './user.alerts.data';




@Component({
  selector: 'aerial-user-alerts',
  templateUrl: './user.alerts.component.html',
})

export class AerialUserAlertsComponent implements OnInit {

	//@Input() public userId: any;
	@Input() public userId: number;
	
	public agentViewInfoList: any;
	public alertMode:string='list';
	public alertId:number;
	public alertInfo: AerialAlertInfo;
	public alertInfoView: AerialAlertInfoView;
	
	constructor(private el: ElementRef, private http: HttpClient, private translate:AerialTranslationService) {
		this.el=el;
	}
	
	ngOnInit() {
		this.options.ajax='/api/rest/user/'+this.userId+'/alert/list';
		
		this.http.get('/api/rest/agent/list').subscribe((result:AerialAgentViewInfo)=>{
			this.agentViewInfoList = result;	
		})
		
	}

	ngOnChanges(changes: SimpleChanges): void {
        if (changes['userId']){
			if (this.userId!=null){
				this.options.ajax='/api/rest/user/'+this.userId+'/alert/list';
			}
		}
    }
    

	public options = {
		ajax: '',
  		columns: [
			{ data: 'statusCode', render: (data, type, row, meta) => this.renderStatusCode(data, type, row, meta) },
			{ data: 'creationDate', render: (data, type, row, meta) => this.renderCreationDate(data, type, row, meta) },
			{ data: 'closedDate', render: (data, type, row, meta) => this.renderClosedDate(data, type, row, meta) },
			{ data: 'typeCode', render: (data, type, row, meta) => this.renderTypeCode(data, type, row, meta) },
			{ data: 'title', render: (data, type, row, meta) => this.renderTitle(data, type, row, meta) },
			{ data: 'agentId', render: (data, type, row, meta) => this.renderAgent(data, type, row, meta) },
	    ],
	    drawCallback: () => {
        	$('.aerial-alert-status').on('click', (event) => {  //click event on span with class `aerial-alert-status`
        		if(event && event.target && event.target.id){
					this.changeAlertMode("details",event.target.id);
				}
			});
			$('.odd').on('click', (event) => {  //click event on tr with class `odd`
        		if(event && event.target && event.target.parentElement && event.target.parentElement.id && event.target.parentElement.id>0){
					this.changeAlertMode("details",event.target.parentElement.id);
				}
			});
			$('.even').on('click', (event) => {  //click event on tr with class `even`
        		if(event && event.target && event.target.parentElement && event.target.parentElement.id && event.target.parentElement.id>0){
					this.changeAlertMode("details",event.target.parentElement.id);
				}
			});
			
      	},
      	rowCallback:(row: any, data: any, index: number) => {
			row.id=data.id;//set alert id in row to be recovered in drawCallback
			return row;
  		}
  		//"order": [[1, 'asc']]

	}
	
	
	public renderStatusCode(data, type, row, meta): string{
		let result:string=data;
		
		if(data && data!=""){
			//result=`<span class="aerial-alert-status aerial-alert-status-`+data.toLowerCase()+`" (click)="changeAlertMode('details',`+row.id+`)">` + this.translate.translate('alert.status.'+data.toLowerCase()) + `</span>`;
			result=`<span class="aerial-alert-status aerial-alert-status-`+data.toLowerCase()+`" id="`+row.id+`">` + this.translate.translate('alert.status.'+data.toLowerCase()) + `</span>`;
		}
		
		return result
	}
	
	public renderCreationDate(data, type, row, meta): string{
		
		let alertDate = new Date();
		alertDate.setTime(data);
		
		const dateFormat = new Intl.DateTimeFormat('es-ES', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		});
	
		return dateFormat.format(alertDate);
	}
	
	public renderClosedDate(data, type, row, meta): string{
		
		let result:string = "";
		
		if(data!=null){
			let alertDate = new Date();
			alertDate.setTime(data);
			
			const dateFormat = new Intl.DateTimeFormat('es-ES', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			});
			
			result = dateFormat.format(alertDate);
		}
	
		return result;
	}
	
	public renderTypeCode(data, type, row, meta): string{
		return this.translate.translate('alert.type.'+data.toLowerCase());
	}
	
	public renderTitle(data, type, row, meta): string{
		return data;
	}
	
	public renderAgent(data, type, row, meta): string{
		let result:string="";
		if(data!=null && data!="" && this.agentViewInfoList!=null){
			for(let i=0;i<this.agentViewInfoList.length;i++){
				if(this.agentViewInfoList[i].id==data){
					result=this.agentViewInfoList[i].fullName;
				}
			}
		}
		return result;
	}
	
	//AER-18
	public changeAlertMode(alertMode,alertId){
		
		this.alertId=alertId;
		
		if(alertId && alertId>0){

			this.http.get('/api/rest/user/'+this.userId+'/alert/'+alertId).subscribe((result:AerialAlertInfo)=>{
				
				this.alertMode=alertMode;
				this.alertInfo = result;
		
				this.alertInfoView={status:"",type:"",agentName:"",creationDate:"",creationTime:"", closedDate:"-", closedTime:""};
				
				//Status
				this.alertInfoView.status=this.translate.translate('alert.status.'+this.alertInfo.statusCode.toLowerCase());
				
				//Type
				this.alertInfoView.type=this.translate.translate('alert.type.'+this.alertInfo.typeCode.toLowerCase());
				
				//Creation Date
				let alertDate = new Date();
				alertDate.setTime(this.alertInfo.creationDate);
				const dateFormat = new Intl.DateTimeFormat('es-ES', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit'
				});
				this.alertInfoView.creationDate=dateFormat.format(alertDate);
				
				//Creation Time		
				const dateTimeFormat = new Intl.DateTimeFormat('es-ES', {
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
				});
				this.alertInfoView.creationTime=dateTimeFormat.format(alertDate);
				
				//Closed Date
				if(this.alertInfo.closedDate!=null){
					let alertClosedDate = new Date();
					alertClosedDate.setTime(this.alertInfo.closedDate);
					this.alertInfoView.closedDate=dateFormat.format(alertClosedDate);
					
					//Closed Time		
					this.alertInfoView.closedTime=dateTimeFormat.format(alertClosedDate);
				}
				
				
				//Agent name
				if(this.agentViewInfoList!=null){
					for(let i=0;i<this.agentViewInfoList.length;i++){
						if(this.agentViewInfoList[i].id==this.alertInfo.agentId){
							this.alertInfoView.agentName=this.agentViewInfoList[i].fullName;
							break;
						}
					}
				}
			})
		}else{
			this.cancelAlert();
		}
	}
	
	public saveAlert(){
		
		if(this.alertInfo!=null){
			this.http.post('/api/rest/user/'+this.userId+'/alert/'+this.alertInfo.id,this.alertInfo).subscribe(
				(result:AerialAlertInfo) => {
					this.alertInfo = null;
					this.alertMode="list";
					this.refreshAlertList();
				}),
				(error) => {
					console.error('Error:', error);
				}
		}
		
	}
	
	public cancelAlert(){
		this.alertInfo = null;
		this.alertMode="list";
		this.refreshAlertList();
	}
	
	public refreshAlertList(){
		$('#userAlertTable').DataTable().ajax.reload();
	}
	//AER-18 end
	

}
