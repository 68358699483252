		<div class="col-md-3 {{budgetClass}}">
			<div style="padding-top:20px;">
				<div class="aerial-label">
					<span class="material-icons aerial-area-icon-14">home</span><span class="aerial-area-icon-text-14">{{'aerial.user.details.home' | translate}}</span>
				</div>
				<div class="aerial-area-text-14">
					<span>{{userComplete.fullAddress}}</span>
				</div>
			</div>
			<div style="padding-top:15px;">
				<div class="aerial-label">
					<span class="material-icons aerial-area-icon-14">home_work</span><span class="aerial-area-icon-text-14">{{'aerial.user.details.typeOfHousing' | translate}}</span>
				</div>
				<div class="aerial-area-text-14">
					<span>{{('aerial.residence.' + userComplete.meta.residenceType) | translate}}</span>
				</div>
			</div>
			<div style="padding-top:15px;">
				<div class="aerial-label">
					<span class="material-icons aerial-area-icon-14">groups</span><span class="aerial-area-icon-text-14">{{'aerial.user.details.liveAlone' | translate}}</span>
				</div>
				<div class="aerial-area-text-14">
					<span>{{(userComplete.meta.alone?'aerial.yes':'aerial.no')|translate}}</span>
				</div>
			</div>
			<div style="padding-top:15px;">
				<div class="aerial-label">
					<span class="material-icons aerial-area-icon-14">pets</span><span class="aerial-area-icon-text-14">{{'aerial.user.details.hasPets' | translate}}</span>
				</div>
				<div class="aerial-area-text-14">
					<span>{{(userComplete.meta.pet?'aerial.yes':'aerial.no')|translate}}</span>
				</div>
			</div>
			<div style="padding-top:15px;">
				<div class="aerial-label">
					<span class="material-icons aerial-area-icon-14">elderly</span><span class="aerial-area-icon-text-14">{{'aerial.user.details.nursingHome' | translate}}</span>
				</div>
				<div class="aerial-area-text-14">
					<span>{{(userComplete.meta.eldercareHome?'aerial.yes':'aerial.no')|translate}}</span>
				</div>
			</div>
			<div style="padding-top:15px;">
				<div class="aerial-label">
					<span class="material-icons aerial-area-icon-14">bedtime</span><span class="aerial-area-icon-text-14">{{'aerial.user.details.timeToGoToSleep' | translate}}</span>
				</div>
				<div class="aerial-area-text-14">
					<span>{{userComplete.meta.bedTime | datetime:'time'}}</span>
				</div>
			</div>
			<div style="padding-top:15px;">
				<div class="aerial-label">
					<span class="material-icons aerial-area-icon-14">wb_sunny</span><span class="aerial-area-icon-text-14">{{'aerial.user.details.timeToWakeUp' | translate}}</span>
				</div>
				<div class="aerial-area-text-14">
					<span>{{userComplete.meta.wakeUpTime | datetime:'time'}}</span>
				</div>
			</div>
		</div>