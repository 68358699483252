import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as actions from './auth.actions';

import { tap, filter, switchMap, map, mergeMap, catchError } from 'rxjs/operators';

// import { fireApp, fireAuth } from '../../firebase';
import { AuthState } from './auth.reducer';
import { Store } from '@ngrx/store';
import { AuthTokenService } from '../../services/auth-token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/services';
import { of } from 'rxjs';

// const auth = fireApp.auth();

@Injectable()
export class AuthEffects {
  redirectUrl: string = '/dashboard';
  loginUrl: string = '/auth/login';

  @Effect()
  login$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LoginAction),
    mergeMap((data: actions.LoginAction) => 
      this.authService.login(data.payload).pipe(
        map(response => new actions.AuthTokenPayload(response)), 
        catchError(error => of(new actions.AuthFailure(error)))  
      )
    )
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LogoutAction),
    mergeMap((data: actions.LogoutAction) => 
      this.authService.logout().pipe(
		map(response => new actions.AuthTokenPayload(null)),	//AER-23
        map(response => this.router.navigate(['/login'])),  
        catchError(error => of(new actions.AuthFailure(error)))  
      )
    )
  );
  
  //AER-24
   @Effect({ dispatch: false })
  pingLogout$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AuthFailure),
    mergeMap((data: actions.AuthFailure) => 
      this.router.navigate(['/login'])) 
  );
  //AER-24

  @Effect({ dispatch: false })
  signup$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.SignupAction),
    tap((data: any) => {
      // auth
      //   .createUserWithEmailAndPassword(
      //     data.payload.username,
      //     data.payload.password
      //   )
      //   .catch(this.dispatchError);
    })
  );

  @Effect({ dispatch: false })
  googleSign$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.GoogleSign),
    tap((data: any) => {
      // auth
      //   .signInWithPopup(new fireAuth.GoogleAuthProvider())
      //   .catch(this.dispatchError);
    })
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LoginRedirect),
    tap((data: any) => {
      this.redirectUrl = data.payload || '';
      this.router.navigate([this.loginUrl]);
    })
  );

  @Effect({ dispatch: false })
  authRedirect$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AuthTokenPayload),
    filter(_ => this.router.url === this.loginUrl),
    tap((data: any) => {
      this.router.navigate([this.redirectUrl]);
    })
  );

  @Effect()
  authUser$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AuthUserChange),
    // switchMap((data: any) => data.payload.getIdToken()),
    tap(_ => (this.authToken.token = _)),
    map(_ => this.authToken.readPayload(_)),

    map(_ => new actions.AuthTokenPayload(_))
  );

  @Effect({ dispatch: false })
  changePassword$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AuthChangePasswordRequired),
    tap((data: any) => {
      	this.router.navigate(['/login/changePassword']); 
    })
  );


  dispatchError = err => {
    this.store.dispatch(
      new actions.AuthFailure({
        code: err.code,
        message: err.message
      })
    );
  };

  constructor(
    private actions$: Actions,
    private store: Store<AuthState>,
    private authToken: AuthTokenService,
    private router: Router,
    private route: ActivatedRoute,
    private authService:AuthService
  ) {
    // auth.onAuthStateChanged(data => {
    //   // console.log('\n\n onAuthStateChanged', data);
    // });

    // auth.onIdTokenChanged(authUser => {
    //   // console.log('\n\n onIdTokenChanged', data);
    //   if (authUser) {
    //     this.store.dispatch(new actions.AuthUserChange(authUser));
    //   } else {
    //     this.authToken.token = null;
    //     this.store.dispatch(new actions.NullToken());
    //   }
    // });


  }
}
