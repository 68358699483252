<div sa-widget [editbutton]="false" [fullscreenbutton]="false" color="blueDark">

  <header>
    <span class="widget-icon"> <i class="fa fa-comments txt-color-white"></i> </span>

    <h2> SmartMessage </h2>

    <div class="widget-toolbar">
      <div dropdown>

        <button type="button" class="btn btn-xs btn-success" dropdownToggle >
          Status <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu pull-right js-status-update" >
          <li role="menuitem"><a class="dropdown-item" (click)="(null)">
            <i class="fa fa-circle txt-color-green"></i> Online</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="(null)">
            <i class="fa fa-circle txt-color-red"></i> Busy
          </a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="(null)">
            <i class="fa fa-circle txt-color-orange"></i> Away
          </a></li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" (click)="(null)">
            <i class="fa fa-power-off"></i> Log Off
          </a></li>
        </ul>
      </div>

    </div>
  </header>

  <div>
    <div class="widget-body widget-hide-overflow no-padding">
      <chat></chat>
    </div>

  </div>
</div>
