import { Component, Input, OnInit, ElementRef} from '@angular/core';
import { DeviceAdditionalDataInfo, IertecDeviceAdditionalDataInfo } from '@app/aerial/model/user.model';
import { HttpClient,HttpParams } from "@angular/common/http";


@Component({
  selector: 'aerial-device-battery-data',
  templateUrl: './devicebox.additionaldata.battery.component.html',
})

export class AerialDeviceBoxBatteryComponent implements OnInit {

	@Input() public data: DeviceAdditionalDataInfo | IertecDeviceAdditionalDataInfo;
	
	deviceType:string = 'AERIAL';
	activeBatteryLevelWarning: boolean = false;
		
	constructor(private el: ElementRef, private http:HttpClient) {
	}
	
	ngOnInit() {
		if(this.data){
			if(this.data.hasOwnProperty('batteryLevel')){
				this.deviceType = 'IERTEC';
			}
		}		
		
		this.findBatteryLevelWarning();
	}
	
	findBatteryLevelWarning(){
		this.http.get("/api/rest/user/device/batteryLevelWarning").subscribe(
			(data: number) => {
				let batteryLevelWarning=data;
				if(batteryLevelWarning!=null && batteryLevelWarning>0){
					if(this.deviceType=='IERTEC'){
						let iertecData : IertecDeviceAdditionalDataInfo = this.data as IertecDeviceAdditionalDataInfo; 
						if(iertecData.batteryLevel!=null && iertecData.batteryLevel<=batteryLevelWarning){
							this.activeBatteryLevelWarning = true;
						}
					}	
				}
				
			},
			(error) => {
				console.error('Error:', error);
			}
		);	
	}
		
}
