import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aerialHourMinuteFormat',
})
export  class AerialHourMinuteFormatPipe implements PipeTransform {

  transform(value: number): string {
    if (typeof value !='number') {
      return '';
    }

    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    let formattedTime = '';
    if (hours > 0) {
      formattedTime += `${hours}h `;
    }

    formattedTime += `${minutes}m`;

    return formattedTime.trim();
  }

}
