import {NgModule} from '@angular/core';

import {SharedModule} from '@app/shared/shared.module'
import { LayoutService } from '@app/core/services/layout.service';
import {AerialUsersRoutingModule} from './users-routing.module';
import {AerialUsersComponent} from './users.component';
import {AerialCommonModule} from "@app/aerial/core/common/common.module";
import { SmartadminDatatableModule } from '@app/shared/ui/datatable/smartadmin-datatable.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AerialUserDetailsModule } from "./details/user.details.module";



@NgModule({
  imports: [
    SharedModule,
    AerialUsersRoutingModule,
    AerialCommonModule,
    SmartadminDatatableModule,
    BsDropdownModule,
    AerialUserDetailsModule
  ],
  declarations: [
    AerialUsersComponent
  ],
  providers: [],
})
export class AerialUsersModule {
	constructor(private layoutService: LayoutService) {
		this.layoutService.onMenuOnTop();
	}
}
