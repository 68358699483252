import { Component, Input, OnInit, ElementRef} from '@angular/core';
import { DeviceAdditionalDataInfo, IertecDeviceAdditionalDataInfo, ThirdPartLocationInfo } from '@app/aerial/model/user.model';
import { HttpClient,HttpParams } from "@angular/common/http";


@Component({
  selector: 'aerial-device-additional-data',
  templateUrl: './devicebox.additionaldata.component.html',
})

export class AerialDeviceBoxAdditionalDataComponent implements OnInit {

	@Input() public data: DeviceAdditionalDataInfo | IertecDeviceAdditionalDataInfo;
	@Input() public editable: boolean;
	@Input() public thirdPartLocationList:ThirdPartLocationInfo[];
	
	deviceType:string = 'AERIAL';
	
		
	constructor(private el: ElementRef, private http:HttpClient) {
	}
	
	ngOnInit() {
		if(this.data){
			if(this.data.hasOwnProperty('batteryLevel')){
				this.deviceType = 'IERTEC';
			}
		}
	}
		
	public renderDate(date:number): string{
		let result="";
		if(date!=null){
			let lastUpdateDate = new Date();
			lastUpdateDate.setTime(date);
			
			const dateFormat = new Intl.DateTimeFormat('es-ES', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			});
			result=dateFormat.format(lastUpdateDate);
		}
		
		return result;
	}
}
