// shared.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AerialTranslatePipe } from '@app/aerial/core/translation/translation.service';
import { AerialDateTimePipe } from '../datetime/datetime.service';
import { AerialAlertBudgetComponent } from '@app/aerial/core/alertbudget/alertbudget.component';
import { AerialDeviceBoxComponent } from '@app/aerial/core/devicebox/devicebox.component';
import { AerialSleepBoxComponent } from '@app/aerial/core/sleepbox/sleepbox.component';
import { AerialActionBudgetComponent } from '../actionbudget/action.component';
import { AerialHourMinuteFormatPipe } from "../datetime/aerial_hour_minute.pipe";
import { AerialProfileService, AerialProfileDirective} from "../profile/profile.service";
import { AerialIntegrationComponent } from '../integration/integration.component';
import { AerialDeviceBoxAdditionalDataComponent } from '@app/aerial/core/devicebox/devicebox.additionaldata.component';
import { AerialDeviceBoxBatteryComponent} from '@app/aerial/core/devicebox/devicebox.additionaldata.battery.component';


@NgModule({
  	imports: [
		CommonModule,
		FormsModule
	],
  	declarations: [
	  AerialTranslatePipe, 
	  AerialAlertBudgetComponent,
	  AerialDeviceBoxComponent,
	  AerialDateTimePipe,
	  AerialSleepBoxComponent,
	  AerialHourMinuteFormatPipe,
  	  AerialActionBudgetComponent,
  	  AerialProfileDirective,
  	  AerialIntegrationComponent,
  	  AerialDeviceBoxAdditionalDataComponent,
  	  AerialDeviceBoxBatteryComponent
	],
  	exports: [
	  AerialTranslatePipe, 
	  AerialAlertBudgetComponent,
	  AerialDeviceBoxComponent,
	  AerialDateTimePipe,
	  AerialSleepBoxComponent,
	  AerialHourMinuteFormatPipe,
	  AerialActionBudgetComponent,
	  AerialProfileDirective,
	  AerialIntegrationComponent,
	  AerialDeviceBoxAdditionalDataComponent,
	  AerialDeviceBoxBatteryComponent
	]	
})
export class AerialCommonModule {}
