		<div class="col-md-12">
			<button class="aerial-action-button pull-right" (click)="changeEditable()" *ngIf="!editable"><i class="material-icons-outlined aerial-color-grey aerial-action-button-icon">mode_edit</i></button>
			<button class="aerial-action-button pull-right"(click)="cancel()" *ngIf="editable" [disabled]="buttonDisabled"><i class="material-icons aerial-color-red aerial-action-button-icon">close</i></button>
			<button class="aerial-action-button pull-right" (click)="save()" *ngIf="editable" [disabled]="buttonDisabled"><i class="material-icons aerial-color-blue aerial-action-button-icon">done</i></button>
		</div>
		<div class="col-md-12">
			<div class="col-md-4">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-16">perm_identity</span><span class="aerial-area-icon-text-14">{{'aerial.user.name.question' | translate}}</span>
					</div>
					<div class="aerial-field">
						<span class="aerial-sublabel">{{'aerial.user.name' | translate}}</span>
						<input type="text" [(ngModel)]="userComplete.user.fullName" [disabled]="!editable">
					</div>
				</div>
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons-outlined aerial-area-icon-16">groups</span><span class="aerial-area-icon-text-14">{{'aerial.user.alone.question' | translate}}</span>
					</div>
					<div class="aerial-field">
						<div class="aerial-radio">
							<input name="alone" ng-control="alone" type="radio" [value]="true"  [(ngModel)]="userComplete.meta.alone" [disabled]="!editable">							
							<span class="">{{'aerial.yes' | translate}}</span>
							<input name="alone" ng-control="alone" type="radio" [value]="false" [(ngModel)]="userComplete.meta.alone" [disabled]="!editable">
							<span class="">{{'aerial.no' | translate}}</span>
						</div>
					</div>
				</div>
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons-outlined aerial-area-icon-16">pets</span><span class="aerial-area-icon-text-14">{{'aerial.user.pet.question' | translate}}</span>
					</div>
					<div class="aerial-field">
						<div class="aerial-radio">
							<input name="pet" ng-control="pet" type="radio" [value]="true"  [(ngModel)]="userComplete.meta.pet" [disabled]="!editable">							
							<span class="">{{'aerial.yes' | translate}}</span>
							<input name="pet" ng-control="pet" type="radio" [value]="false" [(ngModel)]="userComplete.meta.pet" [disabled]="!editable">
							<span class="">{{'aerial.no' | translate}}</span>
						</div>
					</div>
					<div class="aerial-field" *ngIf="userComplete.meta.pet">
						<span class="aerial-sublabel">{{'aerial.size' | translate}}</span>
						<select [(ngModel)]="userComplete.meta.petSize" [disabled]="!editable">
							<option [value]="petSize.value" *ngFor="let petSize of petSizeList">{{petSize.name | translate}}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons-outlined aerial-area-icon-16">home</span><span class="aerial-area-icon-text-14">{{'aerial.user.details.home' | translate}}</span>
					</div>
					<div class="aerial-field">
						<span class="aerial-sublabel">{{'aerial.user.address' | translate}}</span>
						<input type="text" [(ngModel)]="userComplete.fullAddress" maxlength="200" [disabled]="!editable">
					</div>
				</div>
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons-outlined aerial-area-icon-16">home_work</span><span class="aerial-area-icon-text-14">{{'aerial.user.residenceType.question' | translate}}</span>
					</div>
					<div class="aerial-field">
						<div class="aerial-radio">
							<input name="residenceType" ng-control="residenceType" type="radio" [value]="'apartment'"  [(ngModel)]="userComplete.meta.residenceType" [disabled]="!editable">							
							<span class="">{{'aerial.residence.apartment' | translate}}</span>
							<input name="residenceType" ng-control="residenceType" type="radio" [value]="'house'" [(ngModel)]="userComplete.meta.residenceType" [disabled]="!editable">
							<span class="">{{'aerial.residence.house' | translate}}</span>
						</div>
					</div>
					<div class="aerial-field"  *ngIf="userComplete.meta.residenceType=='house'">
						<span class="aerial-sublabel">{{'aerial.residence.floorNumber' | translate}}</span>
						<select [(ngModel)]="userComplete.meta.floorsNumber" [disabled]="!editable">
							 <option [value]="floor.value" *ngFor="let floor of floorList">{{floor.name | translate}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons-outlined aerial-area-icon-16">elderly</span><span class="aerial-area-icon-text-14">{{'aerial.user.eldercarehome.question' | translate}}</span>
					</div>
					<div class="aerial-field">
						<div class="aerial-radio">
							<input name="eldercareHome" ng-control="eldercareHome" type="radio" [value]="true"  [(ngModel)]="userComplete.meta.eldercareHome" [disabled]="!editable">							
							<span class="">{{'aerial.yes' | translate}}</span>
							<input name="eldercareHome" ng-control="eldercareHome" type="radio" [value]="false" [(ngModel)]="userComplete.meta.eldercareHome" [disabled]="!editable">
							<span class="">{{'aerial.no' | translate}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons-outlined aerial-area-icon-16">clear_night</span><span class="aerial-area-icon-text-14">{{'aerial.user.bedTime.question' | translate}}</span>
					</div>
					<div class="aerial-field">
						<span class="aerial-sublabel">{{'aerial.hour' | translate}}</span>
						<select [(ngModel)]="userComplete.meta.bedTime" [disabled]="!editable">
							 <option [value]="time" *ngFor="let time of sleepTimeList">{{time | datetime:'time'}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons-outlined aerial-area-icon-16">sunny</span><span class="aerial-area-icon-text-14">{{'aerial.user.wakeup.question' | translate}}</span>
					</div>
					<div class="aerial-field">
						<span class="aerial-sublabel">{{'aerial.hour' | translate}}</span>
						<select [(ngModel)]="userComplete.meta.wakeUpTime" [disabled]="!editable">
							 <option [value]="time" *ngFor="let time of wakeUpTimeList">{{time | datetime:'time'}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons-outlined aerial-area-icon-16">globe</span><span class="aerial-area-icon-text-14">{{'aerial.user.timezone.question' | translate}}</span>
					</div>
					<div class="aerial-field">
						<span class="aerial-sublabel">{{'aerial.timezone' | translate}}</span>
						<select [(ngModel)]="userComplete.meta.timezone" [disabled]="!editable">
							 <option [value]="timezone.name" *ngFor="let timezone of timeZoneList">{{timezone.name}}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12">		
			<div class="col-md-8">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons-outlined aerial-area-icon-16">beach_access</span><span class="aerial-area-icon-text-14">{{'aerial.user.vacation' | translate}}</span>
						<div class="pull-right"><span style="padding-right: 10px">{{'aerial.active' | translate}}</span> <input type="checkbox" [(ngModel)]="userComplete.vacation.active"  [disabled]="!editable"/></div>
					</div>
					<div class="col-sm-4 aerial-field">
						<div class="form-group">
                      	<div class="input-group">
                        	  <input class="form-control aerial-datepicker" id="from" [(ngModel)]="vacationInitDate" (ngModelChange)="changeVacationInitDate($event)"  [saUiDatepicker]="{
                                    dateFormat: 'dd/mm/yy',
                                    defaultDate: '+1w',
                                    changesMonth: true,
                                    numberOfMonth: 3,
                                    minRestrict: '#to'
                                 }"
                              type="text" placeholder="{{'aerial.from' | translate}}"  [disabled]="!editable">
                          	<span class="input-group-addon" style="border-radius: 50px;"><i class="fa fa-calendar"></i></span>
                      	</div>
                     </div>
                 </div>

                 <div class="col-sm-4 aerial-field">

                      <div class="form-group">
                        <div class="input-group">
                          <input class="form-control aerial-datepicker" id="to" [(ngModel)]="vacationEndDate" (ngModelChange)="changeVacationEndDate($event)" [saUiDatepicker]="{
                                    dateFormat: 'dd/mm/yy',
                                    defaultDate: '+3w',
                                    changesMonth: true,
                                    numberOfMonth: 3,
                                    maxRestrict: '#from'
                                 }"
                                 type="text" placeholder="{{'aerial.to' | translate}}"  [disabled]="!editable">
                          <span class="input-group-addon" style="border-radius: 50px;"><i class="fa fa-calendar"></i></span>
                        </div>
                      </div>

                    </div>
			</div>
	</div>
		</div>