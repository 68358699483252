import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { AuthFailure, LoginAction } from '@app/core/store/auth';
import { LoginPasswordAuthorization } from '@app/shared/login/login.model';
import { LayoutService } from '@app/core/services/layout.service';
import { AppState } from '@app/core/store';
import { Observable } from 'rxjs';
import { AerialProfileService } from '../core/profile/profile.service';
import { HttpClient,HttpParams } from "@angular/common/http";



@Component({
  selector: 'aerial-login',
  templateUrl: './login.component.html',
})
export class AerialLoginComponent implements OnInit {
	loginInfo:LoginPasswordAuthorization;
	authError$: Observable<string>; 
	console = console;
	
	askCode: boolean = false;
	showError: boolean = false;
	errorMsg: string;
	codeInstructions: string;
	
	enableBtn: boolean = true;
	showRequestCodeResponse: boolean = false;
	
	forgotPasswordMode:boolean = false;
		
	constructor(private router: Router, private store:Store<AppState>, private layoutService: LayoutService, private profleService: AerialProfileService, private http:HttpClient) {
		//this.authError$ = this.store.select(state => state.auth.error);
		
		this.store.select(state => state.auth.error).subscribe((error) =>{
			this.enableBtn = true;
			if(error) {
				if(error.error){
					if(!error.error.parameterViolations){
						this.showError = true;
						this.errorMsg = error.error;
						this.askCode = false;
					}else{
						this.askCode = true;
						let codeError = error.error.parameterViolations[0];
						if(codeError){
							this.codeInstructions = codeError.message;	
						}
					}
				}
			}else{
				this.askCode = false;
				this.showError = false;
				this.errorMsg = null;
				this.codeInstructions = null;
			}
		});
		this.store.select((state) => state.auth.user).subscribe((user) => {
		  this.enableBtn = true;
	      if (user) {
		 	profleService.setUpProfile(user.profile);
	        router.navigate(['/portal']); 
	      }
	    });
	 }

	ngOnInit() {
		this.loginInfo= {
			account: '',
			password: '',
			code: null
		};
		this.layoutService.onMenuOnTop();
	}

	login(event: Event){
		this.enableBtn = false;
		this.showError = false;
		event.preventDefault();
		this.codeInstructions = null;
		this.store.dispatch(new LoginAction(this.loginInfo));	
	}

	requestNewCode(event: Event){
		this.codeInstructions = null;
		this.showRequestCodeResponse = false;
		
		this.http.post("/api/rest/login/requestNewCode", this.loginInfo).subscribe(
			(data: any) => {
				this.codeInstructions = 'aerial.login.code.required';
				this.showRequestCodeResponse = true;
			},
			(error) => {
				this.showRequestCodeResponse = true;
			}
		);
	}
	
	changeToForgotPassword(){
		this.forgotPasswordMode = true;
	}
	
	requestNewPassword(event: Event){
		event.preventDefault();
		this.forgotPasswordMode = false;
		this.http.post("/api/rest/login/resetPassword", this.loginInfo).subscribe(
			(data: any) => {
				this.errorMsg = 'aerial.login.newPassword.sent';
				this.showError = true;
			},
			(error) => {
				this.errorMsg = 'aerial.login.newPassword.error';
				this.showError = true;
			}
		);
	}
}
