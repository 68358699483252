<div class="aerial-component {{componentClass}}">
	<div class="col-md-12 aerial-component-header aerial-flex af-create-items-gap-5" >
		<span class ="af-item-expand"><i class="material-icons">directions_walk</i> {{'aerial.user.activity' | translate}}</span>
		<button class="aerial-action-button af-item-fixed" *ngFor="let timeChartType of timeChartTypes" (click)="changeTimeChartType(timeChartType)" [class.selected]="timeChartType == selectedTimeChartType">
			{{timeChartType.label|translate}}
		</button>

	</div>
	<div class="col-md-12 aerial-component-body">
		<div class="highcharts-chart"  style="width: 100%; height: 400px; display: block;"></div>
		<!--highcharts-chart *ngIf="highchartsOptions" [Highcharts]="Highcharts"  [options]="highchartsOptions" [(update)]="highchartsUpdate" style="width: 100%; height: 400px; display: block;"></highcharts-chart-->
		<div class="col-md-12 aerial-flex af-align-items-center af-create-items-gap-5 af-justify-content-center">
			<button (click)="decreaseDate()" class="aerial-action-button">&lt;</button>
			<div class="aerial-action-button  aerial-flex af-create-items-gap-5 af-align-items-center">
				<span class="material-icons">calendar_today</span>
				<input type="text"
					bsDatepicker
					[(ngModel)]="dateToQuery"
					[bsConfig]="bsConfig"
					(ngModelChange)="queryChart()"
					style="border: 0;width: 8em;text-align: center;">

			</div>
			<button (click)="increaseDate()" class="aerial-action-button">&gt;</button>

		</div>
		<!-- div class="col-md-12 aerial-flex af-create-items-gap-5" style="font-size: large;">
			<div [class.aerial-alert-normal]="!isActivityLowAlert()" [class.aerial-alert-warning]="isActivityLowAlert()">
				<div>{{'aerial.user.activity.minutes' | translate}}</div>
				<div>
					<span class="material-icons">hourglass_empty</span>
					<span *ngIf="(userActivityInfo && isNumber( userActivityInfo.activeMinutes)); else noTime">
						{{userActivityInfo.activeMinutes|aerialHourMinuteFormat}}
					</span>
				</div>
				
			</div>
			<div>
				<div>{{'aerial.user.activity.averageRange' | translate}}</div>
				<div>
					<span class="material-icons">hourglass_empty</span>
					<span *ngIf="(userActivityInfo && isNumber( userActivityInfo.usualMinActiveMinutes)); else noTime">
					{{userActivityInfo.usualMinActiveMinutes|aerialHourMinuteFormat}} - {{userActivityInfo.usualMaxActiveMinutes|aerialHourMinuteFormat}}
					</span>
				</div>
			</div>
			<ng-template #noTime>
				{{'aerial.notAvailable'|translate}}
			</ng-template>
		</div -->
		<div class="col-md-6" style="margin-top: 20px">
			<div class="col-md-12">
				<div class="col-md-6">
					<span class="aerial-area-sleep-subtittle">{{'aerial.user.activity.minutes' | translate}}</span>
				</div>
				<div class="col-md-6">
					<div class="aerial-area-sleep-data">
						<span class="material-icons">hourglass_empty</span>
						<span *ngIf="(userActivityInfo && isNumber( userActivityInfo.activeMinutes)); else noTime">
							{{userActivityInfo.activeMinutes|aerialHourMinuteFormat}}
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="col-md-6">
					<span class="aerial-area-sleep-range">{{'aerial.user.activity.averageRange' | translate}}</span>
				</div>
				<div class="col-md-6">
					<div class="aerial-area-sleep-range">
						<span class="material-icons">hourglass_empty</span>
						<span *ngIf="(userActivityInfo && isNumber( userActivityInfo.usualMinActiveMinutes)); else noTime">
						{{userActivityInfo.usualMinActiveMinutes|aerialHourMinuteFormat}} - {{userActivityInfo.usualMaxActiveMinutes|aerialHourMinuteFormat}}
						</span>
					</div>
				</div>	
			</div>
			<ng-template #noTime>
				{{'aerial.notAvailable'|translate}}
			</ng-template>
		</div>
	</div>
</div>

